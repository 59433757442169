import {SetupContext} from "vue/types/v3-setup-context";

interface ButtonsForm {
    captionNo?: string;
    captionYes: string;
}
export function useFormBuilder(props: any, context: SetupContext, formConfigSchema: unknown = null) {
    let initDataForm;
    if (props.data?.init != undefined) {
        initDataForm = props.data.init;
    } else {
        initDataForm = props.data;
    }
    const buttonsForm: ButtonsForm = {
        captionYes: "buttons.actions.save",
    };
    function closeForm() {
        context.emit("onClose");
    }
    function execEventComponent({action, data}): void {
        switch (action) {
            case "another":
                break;
            default:
        }
    }
    return {
        formConfig: formConfigSchema,
        initDataForm,
        buttonsForm,
        closeForm,
        execEventComponent
    }
}