
import {mapActions} from "vuex";
import ListItemsMenu from "./ListItemsMenu.vue";
import {defineComponent} from "vue";

export default defineComponent({
    name: "",
    components: {ListItemsMenu},
    props: ["loggedIn", "userProfile"],
    data() {
        return {
            drawer: false,
        };
    },

    methods: {
        ...mapActions("user", {
            loginUser: "login",
            logoutUser: "logout",
        }),

        async login() {
            await this.loginUser();
            await this.$router.push("/storefront");
        },

        async logout() {
            await this.logoutUser();
            await this.$router.push("/login");
        },
    },
});
