import {initializeApp} from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import env from "@/env";

const app = initializeApp(env.FIREBASE_CONFIG);

if (env.MODE == "prod") {
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(env.RECAPTCHA_SITE_KEY),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
    });
}

export default app;

//https://firebase.google.com/docs/firestore/security/get-started#auth-required
