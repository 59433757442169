
import {defineComponent} from "vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import HeaderView from "@/components/layout/HeaderView.vue";
import ListExercises from "@/components/exercises/ListExercises.vue";

export default defineComponent({
    name: "Exercises",
    components: {
        ListExercises,
        HeaderView,
        FormDialog,
    },
    methods: {
        openNewItemDialog() {
            let data = {};
            let params = {
                steps: [
                    {
                        title: this.$t("text.newExercise"),
                        component: "exercise/ExerciseForm",
                    },
                ],
                data,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
    },
});
