import {FirestoreError} from "firebase/firestore";
import {showMessage} from "@/helpers/messages";

export function persistenceErrorHandler(reason: unknown) {
    let errorMessage: string;
    if (reason instanceof FirestoreError) {
        errorMessage = reason.message;
    } else if (typeof reason == "string") {
        errorMessage = reason;
    } else {
        console.error("Unknown error detected: ")
        console.error(reason);
        errorMessage = "Unknown error";
    }
    showMessage({
        text: errorMessage,
        type: "error",
    });
}

export function observeErrorHandler(error: FirestoreError | { message: string; code: string; }) {
    showMessage({
        text: "Failed to retrieve items",
        type: "error",
    });
    console.error(error);
}