import Language from "@/model/Language";
import {pickBy, trim} from "lodash-es";

type Localized<T> = Partial<Record<Language, T>> & {
    [Language.Default]: T;
};
export function minifyLocalized(localized: Localized<string>): Localized<string> {
    return  pickBy(localized, (value, key) => {
        if (key == Language.Default) return true;
        else {
            return value.length != 0
        }
    }) as Localized<string>;
}
export function isLocalizedBlank(localized?: Localized<string>): boolean {
    if (localized == undefined) return true;
    const defaultText = trim(localized[Language.Default]);
    return defaultText.length == 0;
}

export default Localized;
