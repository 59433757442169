
import {defineComponent} from "vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import HeaderView from "@/components/layout/HeaderView.vue";
import Spacer from "@/plugins/components/builders/form-builder-vuetify/src/components/elements/Spacer.vue";
import ListShelves from "@/components/storefront/ListShelves.vue";
import storefrontRepository from "@/repositories/storefrontRepository";
import ListShelfItems from "@/components/storefront/ListShelfItems.vue";
import DbShelf from "@/model/DbShelf";
import {persistenceErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    name: "Storefront",
    components: {
        ListShelfItems,
        ListShelves,
        Spacer,
        HeaderView,
        FormDialog,
    },
    props: {
        storefrontId: {
            type: String,
            default: storefrontRepository.MAIN_TEST_STOREFRONT_KEY
        }
    },
    data(): {
        selectedShelf?: DbShelf
    } {
        return {
            selectedShelf: undefined
        }
    },
    methods: {
        openNewShelfDialog() {
            let data = {
                storefrontId: this.storefrontId
            };
            let params = {
                steps: [
                    {
                        title: this.$t("text.newShelf"),
                        component: "shelf/ShelfForm",
                    },
                ],
                data,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        openAddShelfItemDialog() {
            let data = {
                storefrontId: this.storefrontId,
                shelfId: this.selectedShelf?.id
            };
            let params = {
                steps: [
                    {
                        title: this.$t("text.addShelfItemsToShelf"),
                        component: "shelf/AddShelfItemsToShelfForm",
                    },
                ],
                data,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async publishStorefront(): Promise<void> {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.publish.storefront.title`),
                    this.$t(`confirm.publish.storefront.message`)
                )
            ) {
                Vue.showLoadingOverlay(true)
                await storefrontRepository
                    .publishToProdStorefront(this.storefrontId)
                    .catch(persistenceErrorHandler);
                Vue.showLoadingOverlay(false)
            }
        },

        async shelfClickAction({item, event}) {
            switch (event) {
                case "onRowClick":
                    this.selectedShelf = item;
                    break;
            }
        },
    },
});
