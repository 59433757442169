
import Vue, {defineComponent} from "vue";

export default defineComponent({
    name: "DynamicModule",

    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },

        type: {
            type: String,
            default: "",
        },

        options: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data(): {
        component: (() => Promise<unknown>) | null
    } {
        return {
            component: null
        }
    },
    watch: {
        type(newValue: string) {
            this.updateComponent(newValue)
        }
    },
    mounted() {
        this.updateComponent(this.type)
    },
    methods: {
        updateComponent(type: string | null) {
            if (!this.type) {
                this.component = null;
            }
            // Check if component exists in development mode

            if (Vue.config.devtools) {
                import("./" + type)
                    .then()
                    .catch(() => {
                        alert("Module " + type + " not found")
                    })
            }

            this.component = () => import("./" + type);

        },
        onDataEvent(data) {
            this.$emit("onDataEvent", data);
        },
        onClose() {
            this.$emit("onClose");
        },
    },
});
