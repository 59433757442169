import firebaseApp from "@/firebaseApp";
import {
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    documentId,
    DocumentReference,
    FieldPath,
    Firestore,
    FirestoreDataConverter,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    OrderByDirection,
    Query,
    query,
    QueryConstraint,
    QueryDocumentSnapshot,
    runTransaction,
    setDoc,
    startAfter,
    startAt,
    updateDoc,
    where,
    WhereFilterOp,
    WriteBatch,
    writeBatch,
} from "firebase/firestore";
import _ from "lodash-es";
import {Transaction} from "@firebase/firestore";
import {Unsubscribe} from "@/repositories/common";

type DocumentWithOptionalId<T extends DocumentData> = Omit<T, "id"> & { id?: string };
type DocumentWithId<T extends DocumentData> = T & { id: string };

const localFilters = ["text-contains", "array-not-contains", "array-contains-all"]
function typedCollection<T extends DocumentData>(
    firestore: Firestore,
    collectionName: string
): CollectionReference<T> {
    const converter: FirestoreDataConverter<T> = {
        toFirestore: (data: T) => data,
        fromFirestore: (snap: QueryDocumentSnapshot) =>
            snap.data() as T
    }
    return collection(firestore, collectionName).withConverter(converter);
}

type LocalWhereFilterOp = "text-contains" | "array-not-contains" | "array-contains-all";
export type FindOptionsWhereFilter = {
    fieldPath: string;
    opStr: WhereFilterOp | LocalWhereFilterOp;
    value: unknown;
};
export type FindOptions = {
    queryConstraints?: QueryConstraint[];
    whereFilters?: FindOptionsWhereFilter[];
    startAfter?: string;
    startAt?: string;
    limit?: number;
    orderBy?: {
        fieldPath: string | FieldPath;
        direction?: OrderByDirection;
    };
};
const findAllQuery = <T extends DocumentData>(
    collectionName: string,
    options: FindOptions
): Query<T> => {
    const firestore = getFirestore(firebaseApp);
    const collectionRef = typedCollection<T>(firestore, collectionName);

    const queryConstraints: QueryConstraint[] = [];
    if (options.queryConstraints != undefined) {
        queryConstraints.push(...options.queryConstraints);
    }
    if (options.whereFilters != undefined) {
        for (const whereFilter of options.whereFilters) {
            // Apply remote persistence filters
            if (!localFilters.includes(whereFilter.opStr)) {
                queryConstraints.push(
                    where(
                        whereFilter.fieldPath,
                        <WhereFilterOp>whereFilter.opStr,
                        whereFilter.value
                    )
                );
            }
        }
    }
    if (options.orderBy != undefined) {
        queryConstraints.push(
            orderBy(
                options.orderBy.fieldPath ?? documentId(),
                options.orderBy.direction
            )
        );
    } else {
        queryConstraints.push(orderBy(documentId()));
    }
    if (options.startAfter != undefined) {
        queryConstraints.push(startAfter(options.startAfter));
    }
    if (options.startAt != undefined) {
        queryConstraints.push(startAt(options.startAt));
    }
    if (options.limit != undefined) {
        queryConstraints.push(limit(options.limit));
    }
    return query(collectionRef, ...queryConstraints);
};

// Returns filtered results
function applyLocalFilter<T extends DocumentData>(
    results: DocumentWithId<T>[],
    whereFilters: FindOptionsWhereFilter[],
    limit: number
): DocumentWithId<T>[] {
    const localWhereFilters = whereFilters.filter(
        (el) => localFilters.includes(el.opStr)
    );
    if (localWhereFilters.length > 0) {
        const newResults: DocumentWithId<T>[] = [];
        for (const item of results) {
            for (const whereFilter of localWhereFilters) {
                const valueAtKeyPath = whereFilter.fieldPath
                    .split(".")
                    .reduce((previous, current) => previous[current], item);
                switch (whereFilter.opStr) {
                    case "text-contains":
                        // @ts-ignore
                        if (valueAtKeyPath.toLowerCase().includes(whereFilter.value.toLowerCase())) {
                            newResults.push(item);
                        }
                        break;
                    case "array-not-contains":
                        if (Array.isArray(valueAtKeyPath) && !valueAtKeyPath.includes(whereFilter.value)) {
                            newResults.push(item);
                        }
                        break;
                    case "array-contains-all":
                        if (Array.isArray(valueAtKeyPath) &&
                            // @ts-ignore
                                _.difference(whereFilter.value, valueAtKeyPath).length === 0) {
                            newResults.push(item);
                        }
                }
            }
            if (newResults.length > limit) {
                break;
            }
        }
        return newResults;
    } else {
        return results;
    }
}

const observeAll = <T extends DocumentData>(
    collectionName: string,
    options: FindOptions,
    onNext: (result: DocumentWithId<T>[]) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    const queryLimit = options.limit ?? 10;
    const unsubscribe = onSnapshot(
        findAllQuery<T>(collectionName, options),
        (result) => {
            const mappedResult = result.docs.map((el) => {
                return {
                    id: el.id,
                    ...el.data(),
                };
            });
            const filteredResults = applyLocalFilter(
                mappedResult,
                options.whereFilters ?? [],
                queryLimit
            );
            if (
                filteredResults.length < queryLimit &&
                mappedResult.length >= queryLimit
            ) {
                // Insufficient records fetched, try to parse more
                unsubscribe();
                return observeAll<T>(
                    collectionName,
                    {
                        ...options,
                        limit: queryLimit * 4,
                    },
                    onNext,
                    onError
                );
            } else {
                onNext(filteredResults);
            }
        },
        (error) => {
            onError(error);
        }
    );
    return unsubscribe;
};
const findAll = async <T extends DocumentData>(
    collectionName: string,
    options: FindOptions
): Promise<DocumentWithId<T>[]> => {
    const result = await getDocs(findAllQuery<T>(collectionName, options));
    return result.docs.map((el) => {
        return {
            id: el.id,
            ...el.data(),
        };
    });
};
const getNewDocumentId = (collectionName: string): string => {
    const firestore = getFirestore(firebaseApp);
    return doc(collection(firestore, collectionName)).id;
};
const find = async <T extends DocumentData>(
    collectionName: string,
    documentId: string
): Promise<DocumentWithId<T> | undefined> => {
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(typedCollection<T>(firestore, collectionName), documentId);
    const result = await getDoc(docRef);
    const data = result.data();
    if (data != undefined) {
        return ({
            id: result.id,
            ...data,
        });
    } else {
        return undefined;
    }
};
const observe = <T extends DocumentData>(
    collectionName: string,
    documentId: string,
    onNext: (result?: DocumentWithId<T>) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(typedCollection<T>(firestore, collectionName), documentId);
    return onSnapshot(
        docRef,
        (result) => {
            const data = result.data();
            if (data != null) {
                onNext({
                    id: result.id,
                    ...data,
                });
            } else {
                onNext(undefined);
            }
        },
        (error) => {
            onError(error);
        }
    );
};
type SetOptions<T extends DocumentData> = {
    // If id is not set it will be generated
    data: DocumentWithOptionalId<T>;
    merge?: boolean;
};
const set = async <T extends DocumentData>(
    collectionName: string,
    options: SetOptions<T>
): Promise<{ documentId: string }> => {
    try {
        const firestore = getFirestore(firebaseApp);
        const documentData = _.clone(options.data);
        const documentId = documentData.id;
        delete documentData.id;
        const docRef = doc(
            collection(firestore, collectionName),
            documentId
        );
        await setDoc(docRef, documentData, {merge: options.merge});
        return {documentId: docRef.id};
    } catch (error) {
        console.error(`Save obj ${collectionName} failed`, error);
        throw error;
    }
};
type UpdateOptions<T extends DocumentData> = {
    // If id is not set it will be generated
    data: DocumentWithOptionalId<T>;
};
const update = async <T extends DocumentData>(
    collectionName: string,
    options: UpdateOptions<T>
): Promise<{ documentId: string }> => {
    const firestore = getFirestore(firebaseApp);
    const documentData = _.clone(options.data);
    const documentId = documentData.id;
    delete documentData.id;
    const docRef = doc(
        collection(firestore, collectionName),
        documentId
    );
    await updateDoc(docRef, documentData);
    return {documentId: docRef.id};
};
const remove = async (
    collectionName: string,
    documentId: string
): Promise<void> => {
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(collection(firestore, collectionName), documentId);
    await deleteDoc(docRef);
    return;
};
const documentReference = <T extends DocumentData>(
    collectionName: string,
    documentId: string
): DocumentReference<T> => {
    const firestore = getFirestore(firebaseApp);
    return doc(typedCollection<T>(firestore, collectionName), documentId);
};
const batch = async (exec: (writeBatch: WriteBatch) => void): Promise<void> => {
    const firestore = getFirestore(firebaseApp);
    const writeBatchInstance = writeBatch(firestore);
    exec(writeBatchInstance);
    await writeBatchInstance.commit();
};
const executeTransaction = async <T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> => {
    const firestore = getFirestore(firebaseApp);
    const transactionRet = await runTransaction(firestore, updateFunction);
    return transactionRet;
};


export default {
    getNewDocumentId,
    findAll,
    observeAll,
    find,
    observe,
    update,
    set,
    remove,
    documentReference,
    batch,
    executeTransaction,
};