import store from '../store';
import {TranslateResult} from "vue-i18n";

const typeMessage = {
    "success": {color: "success"},
    "error": {color: "danger"},

};

interface ShowMessagePayload {
    type: "error" | "success",
    text: TranslateResult | string
}

const showMessage = (payload: ShowMessagePayload) => {
    const fullPayload: Record<string, any> = payload;
    fullPayload.color = typeMessage[payload.type].color;
    if (!Object.prototype.hasOwnProperty.call(fullPayload, 'timeout')) {
        fullPayload.timeout = 1000;
    }
    store.commit('snackbar/SHOW_MESSAGE', fullPayload);
};

export {
    ShowMessagePayload,
    showMessage
}
