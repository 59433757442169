
import Vue, {defineComponent, VueConstructor} from "vue";
import Language from "@/model/Language";
import {PropType} from "vue/types/options";
import storefrontRepository from "@/repositories/storefrontRepository";
import DbShelf from "@/model/DbShelf";
import unsubscribeMixin from "@/plugins/components/utils/persistence/unsubscribeMixin";
import DbShelfItem from "@/model/DbShelfItem";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    name: "ListShelves",
    mixins: [unsubscribeMixin],
    props: {
        tableActions: {
            type: Array as PropType<("remove" | "edit" | "delete")[]>,
            default: () => ["edit", "delete"],
        },
        storefrontId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            tableLoading: false,
            buttonsForm: {
                captionNo: "buttons.actions.reset",
                captionYes: "buttons.actions.find",
            },
            headers: [
                {
                    text: "Position",
                    value: `position`,
                    sortable: false,
                    align: "center",
                    width: "50px"
                },
                {
                    text: "Title",
                    value: `title.${Language.Default}`,
                    sortable: false,
                },
                {
                    text: "Subtitle",
                    value: `subtitle.${Language.Default}`,
                    sortable: false,
                },
                {text: "Actions", value: "actions", sortable: false, width: "80px"},
                {text: "", value: "selectionIcon", sortable: false, width: "40px"},
            ],
            selectedShelfId: undefined,
            shelves: Array<DbShelf>(),
        };
    },

    mounted() {
        this.loadItems()
    },

    methods: {
        addActions(next: DbShelf[]) {
            if (this.tableActions.length > 0) {
                next.forEach((el: any) => {
                    el.actions = {};
                    for (const action of this.tableActions) {
                        el.actions[action] = true;
                    }
                });
            }
        },
        addPosition(next: DbShelf[]) {
            next.forEach((el: any, index: number) => {
                el.position = index;
                el.selectionIcon = "➡️"
            });
        },
        async loadItems() {
            this.tableLoading = true;
            this.unsubscribeAll()
            const unsubscribe = storefrontRepository.observeStorefront(
                this.storefrontId,
                next => {
                    const shelves = next?.shelves ?? [];
                    this.addActions(shelves);
                    this.addPosition(shelves);
                    this.shelves = shelves;

                    const selectedShelfExists = shelves.some(el => el.id == this.selectedShelfId)
                    if (!selectedShelfExists) {
                        this.selectedShelfId = undefined;
                        this.$emit("onDataEvent", {item: undefined, event: "onRowClick"});
                    }
                    this.tableLoading = false;
                },
                error => {
                    observeErrorHandler(error)
                    this.tableLoading = false;
                }
            )
            this.unsubscribes.push(unsubscribe)
        },
        openEditItemDialog(item: DbShelfItem) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.editShelf"),
                        component: "shelf/ShelfForm",
                    },
                ],
                data: {init: item, storefrontId: this.storefrontId},
            };

            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async removeShelf(item: DbShelf) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.delete.item.title`),
                    this.$t(`confirm.delete.item.message`, {
                        name: item.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true
                await storefrontRepository.removeShelf(
                    this.storefrontId,
                    item.id
                ).catch(persistenceErrorHandler)
                this.tableLoading = false
            }
        },
        async clickAction({item, event, oldIndex, newIndex}) {
            switch (event) {
                case "onActionDelete":
                    await this.removeShelf(
                        item
                    );
                    break;
                case "onActionEdit":
                    this.openEditItemDialog(item);
                    break;
                case "onRowClick":
                    this.selectedShelfId = item.id;
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onRowMoved":
                    this.tableLoading = true
                    await storefrontRepository.moveShelf(
                        this.storefrontId,
                        oldIndex,
                        newIndex
                    ).catch(persistenceErrorHandler);
                    this.tableLoading = false
                    break;
            }
        },
    },
});
