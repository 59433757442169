
import elementsMixin from "./elementsMixin";
import Vue, {defineComponent, VueConstructor} from "vue";
import {Unsubscribe} from "@/repositories/common";
import unsubscribeMixin from "@/plugins/components/utils/persistence/unsubscribeMixin";
import {observeErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    mixins: [elementsMixin, unsubscribeMixin],
    data() {
        return {
            loading: false,
            items: new Array<{ key: string, text: string }>(),
            selectedItems: new Array<{ key: string, text: string }>(),
            search: null,
        }
    },

    watch: {
        selectedItems(val, prev) {
            if (val.length === prev.length) return;
            this.createChips(val);
            this.updateSelectedItems();
        },
    },

    async created() {
        await this.initItems();
    },

    computed: {
        options() {
            return {...this.optionsComponent};
        },
    },

    methods: {

        enabledActions(action) {
            return this.field.actions && this.field.actions[action];
        },
        filter(item, queryText, itemText) {
            if (item.header) return false;

            const hasValue = (val) => (val != null ? val : "");

            const text = hasValue(itemText);
            const query = hasValue(queryText);

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },

        createChips(val) {
            this.selectedItems = val.flatMap((v) => {
                if (typeof v === "string") {
                    v = v.trim();
                    let insert = true;
                    if (!this.enabledActions("create")) {
                        insert = this.items.some((item) => item.text === v);
                    }

                    if (v !== "" && insert) {
                        v = {
                            key: v,
                            text: v
                        };

                        this.items.push(v);
                        return v;
                    } else {
                        return []
                    }
                } else {
                    return v;
                }
            });
        },

        async initItems() {
            // Unsubscribe if previously subscribed
            this.loading = true;
            this.unsubscribeAll();
            const rawItems = this.field.items;
            if (Array.isArray(rawItems)) {
                this.setItems(rawItems);
                this.loading = false;
            } else if (typeof rawItems == "function") {
                // if it has 0 args we consider it a promise, else an observable (that returns an instance of Unsubscribe)
                if (rawItems.length === 0) {
                    const items = await rawItems();
                    this.setItems(items);
                    this.loading = false;
                } else {
                    const unsubscribe: Unsubscribe = rawItems((onNext) => {
                        this.setItems(onNext);
                        this.loading = false;
                    }, observeErrorHandler);
                    this.unsubscribes.push(unsubscribe)
                }
            }
        },

        setItems(newItems) {
            this.items = newItems.map((tag) => {
                let text;
                let key;
                if (typeof tag == "string") {
                    text = tag;
                    key = tag;
                } else if (typeof tag == "object" && Object.prototype.hasOwnProperty.call(tag, "text")) {
                    text = tag.text;
                    key = tag.key ?? tag.text;
                } else {
                    text = tag;
                    key = tag;
                }
                return {text: text, key: key};
            });
            this.initChips();
        },

        initChips() {
            if (this.model[this.element] !== undefined) {
                // Set selected items
                this.model[this.element].forEach((tag) => {
                    const containsItem = this.selectedItems.some((el) => el.key === tag);
                    const itemText = this.items.find(el => el.key === tag)
                    if (!containsItem && itemText != undefined) {
                        this.selectedItems.push({key: itemText.key, text: itemText.text});
                    }
                });
            } else {
                Vue.set(this.model, this.element, []);
                this.$emit('input', this.model)
            }
        },

        updateSelectedItems() {
            let items = this.selectedItems.map((item) => {
                if (item.key == undefined) {
                    return item;
                }
                item.key = item.key.trim();
                return item.key;
            });

            Vue.set(this.model, this.element, items);
            this.$emit('input', this.model)
        },
    },
});
