
import ViewExercises from "@/views/Exercises.vue";
import {mapGetters} from "vuex";

export default {
    name: "home",
    components: {
        ViewExercises,
    },
    computed: {
        ...mapGetters("user", {
            loggedIn: "loggedIn",
            userProfile: "userProfile",
        }),
    },
};
