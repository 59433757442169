import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=e492752a&scoped=true&"
import script from "./FormBuilder.vue?vue&type=script&lang=ts&"
export * from "./FormBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=e492752a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e492752a",
  null
  
)

export default component.exports