
import elementsMixin from "./elementsMixin";
import Vue, {defineComponent} from "vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default defineComponent({
    name: "FileUpload",
    mixins: [elementsMixin],

    data(): {
        selectedFile: null | File,
        imagePreviewURL: string | null,
        videoPreviewURL: string | null,
        errorMessages: LocaleMessage | string
    } {
        return {
            selectedFile: null,
            imagePreviewURL: null,
            videoPreviewURL: null,
            errorMessages: "",
        };
    },
    async created() {
        if (this.model[this.element] != undefined) {
            if (Object.prototype.hasOwnProperty.call(this.field, "imagePreview")) {
                this.imagePreviewURL = await this.field.imagePreview(this.model[this.element]);
            } else if (Object.prototype.hasOwnProperty.call(this.field, "videoPreview")) {
                this.videoPreviewURL = await this.field.videoPreview(this.model[this.element]);
            }
            if (this.model[this.element] instanceof File) {
                this.selectedFile = this.model[this.element];
            }
        }
    },
    methods: {
        async changeFile(payload) {
            if (this.field.rulesFile) {
                let error = await this.field.rulesFile(payload);
                this.errorMessages = error
                    ? this.$t(error.text, error.params)
                    : "";
            }

            if (this.errorMessages === "") {
                this.previewImage(payload);
            } else {
                this.removeImage();
            }
        },

        previewImage(payload) {
            Vue.set(this, "selectedFile", payload);
            Vue.set(this.model, this.element, payload);
            if (payload) {
                if (Object.prototype.hasOwnProperty.call(this.field, "videoPreview")) {
                    this.videoPreviewURL = URL.createObjectURL(payload);
                } else {
                    this.imagePreviewURL = URL.createObjectURL(payload);
                }
                URL.revokeObjectURL(payload);
            } else {
                this.imagePreviewURL = null;
            }
        },

        removeImage() {
            Vue.set(this, "selectedFile", undefined);
            Vue.set(this.model, this.element, undefined);
            this.imagePreviewURL = null;
            this.videoPreviewURL = null;
        },
    },
});
