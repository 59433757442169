import firestoreRepository from "./firestoreRepository";
import settingsRepository from "./settingsRepository";
import {DocumentData, increment, serverTimestamp, deleteField} from "firebase/firestore";
import DbPage from "@/model/DbPage";
import {Unsubscribe, updateTagsCollection} from "@/repositories/common";
import storefrontRepository from "@/repositories/storefrontRepository";
import {DbIdentifiable} from "@/model/DbIdentifiable";
import {isLocalizedBlank, minifyLocalized} from "@/model/Localized";
import {clone} from "lodash-es";

const COLLECTION_NAME = "pages";

const getNewPageId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};
const findPages = async (
): Promise<DbPage[]> => {
    return await firestoreRepository.findAll(COLLECTION_NAME, {});
};
const observePage = (
    id: string,
    onNext: (result?: DbPage) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return firestoreRepository.observe(COLLECTION_NAME, id, onNext, onError);
};

const findPage = async (id: string): Promise<DbPage | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};

const createPage = async (item: DbPage): Promise<void> => savePage(item, true)
const updatePage = async (item: Partial<DbPage> & DbIdentifiable): Promise<void> =>
    savePage(item, false)

const savePage = async (item: Partial<DbPage> & DbIdentifiable, create: boolean): Promise<void> => {
    const oldItem = await findPage(item.id);
    // If it is being set private it must not be used in any public shelfItem
    if (oldItem != null && !oldItem.isPrivate && (item.isPrivate == true)) {
        await checkNoPublicParents(item.id);
    }
    await firestoreRepository.batch((writeBatch) => {
        // Update Tags
        if (item.tags != null) {
            updateTagsCollection(
                writeBatch,
                settingsRepository.PAGE_TAGS_KEY,
                oldItem?.tags ?? [],
                item.tags
            );
        }

        const itemClone: DocumentData = clone(item);
        delete itemClone.id;
        if (itemClone.title != undefined) {
            itemClone.title = minifyLocalized(itemClone.title);
        }
        if (itemClone.subtitle != undefined) {
            itemClone.subtitle = minifyLocalized(itemClone.subtitle);
        }
        if (itemClone.description != undefined) {
            itemClone.description = minifyLocalized(itemClone.description);
        }
        if (itemClone.duration === null) {
            itemClone.duration = deleteField();
        } else if (itemClone.duration != undefined) {
            if (!isLocalizedBlank(itemClone.duration)) {
                itemClone.duration = minifyLocalized(itemClone.duration);
            } else {
                itemClone.duration = deleteField();
            }
        }
        if (itemClone.tools === null) {
            itemClone.tools = deleteField();
        } else if (itemClone.tools != undefined) {
            if (!isLocalizedBlank(itemClone.tools)) {
                itemClone.tools = minifyLocalized(itemClone.tools);
            } else {
                itemClone.tools = deleteField();
            }
        }
        if (itemClone.instructions != undefined) {
            itemClone.instructions = minifyLocalized(itemClone.instructions);
        }
        if (create) {
            itemClone.createdAt = serverTimestamp();
        } else {
            delete itemClone.createdAt;
        }
        const docRef = firestoreRepository.documentReference(
            COLLECTION_NAME,
            item.id
        );
        writeBatch.set(docRef, itemClone, {merge: true});
    });
};

async function checkNoParents(id: string) {
    const linkedStorefronts = await storefrontRepository.findStorefronts({ itemId: id});
    if (linkedStorefronts.length > 0) {
        throw "You can't delete a page if it is in a shelfItem."
    }
}
async function checkNoPublicParents(itemId: string) {
    const linkedStorefronts = await storefrontRepository.findStorefronts({ itemId: itemId, isPrivate: false});
    if (linkedStorefronts.length > 0) {
        throw "Remove the page from all public storefronts before setting it private"
    }
}

const removePage = async (id: string): Promise<void> => {
    const item = await findPage(id);
    if (item == undefined) return;
    await checkNoParents(id);
    await firestoreRepository.batch((writeBatch) => {

        const decrementTagsDoc = {};
        for (const tag of item.tags) {
            decrementTagsDoc[`value.${tag}.count`] = increment(-1);
        }

        // Decrement settings tags count
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.PAGE_TAGS_KEY
            ),
            decrementTagsDoc
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewPageId,
    savePage,
    createPage,
    updatePage,
    findPages,
    observePage,
    findPage,
    removePage,
};
