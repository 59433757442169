import {LocaleMessages} from "vue-i18n";

export const DICTIONARY: LocaleMessages = {
    en: {
        components: {
            combochips: {
                create: "Press enter to create a tag",
                header: {
                    create: "Select or create tags",
                    search: "Select tags",
                },
            },
        },

        buttons: {
            actions: {
                ok: "Ok",
                add: "Add",
                delete: "Delete",
                update: "Edit",
                find: "Search",
                new: "New",
                reset: "Reset",
                close: "Cancel",
                save: "Confirm",
                publishStorefront: "🚀 Publish in PROD"
            },
        },
        word: {
            storefront: "Storefront",
            exercise: "Exercise",
            exercises: "Exercises",
            workout: "Workout",
            workouts: "Workouts",
            pages: "Pages",
            page: "Page",
            program: "Program",
            programs: "Programs"
        },

        text: {
            addChildrenToWorkout: "Add children to workout",
            addChildrenToProgram: "Add children to program",
            newExercise: "New exercise",
            newProgram: "New program",
            newWorkout: "New workout",
            newPage: "New page",
            editExercise: "Edit exercise",
            editPage: "Edit page",
            editChildRelation: "Edit child relation",
            newShelf: "New shelf",
            editShelf: "Edit shelf",
            editShelfItem: "Edit shelf item",
            addShelfItemsToShelf: "Add items to shelf",
            editProgram: "Edit program",
            editWorkout: "Edit workout",
            parentsList: "Parents list",
        },

        rules: {
            image: {
                format: "Valid format:{x} x {y}",
            },
        },
        message: {
            save: {
                item: {
                    update: "Item has been edited successfully",
                    add: "Item has been added successfully",
                },
            },
        },

        confirm: {
            delete: {
                item: {
                    title: "Delete item",
                    message:
                        "You're deleting <b>{name}</b> item permanently. Do you want to proceed anyway?",
                },
            },
            remove: {
                item: {
                    title: "Remove item",
                    message:
                        "You're removing <b>{name}</b> item. Do you want to proceed?",
                },
            },
            publish: {
                storefront: {
                    title: "Publish in production environment",
                    message: "Your changes are going to be permanent. Do you want to proceed anyway?"
                },
                program: {
                    title: "Set program as public",
                    message: "This program may contain private items. " +
                        "If you set this program as public all items contained in it will be set as public as well."
                }
            },
        },
    },
};
export default {
    DICTIONARY,
};
