
import {defineComponent} from "vue";
import HeaderView from "@/components/layout/HeaderView.vue";
import Language from "@/model/Language";
import FormDialog from "@/components/dialog/FormDialog.vue";
import DbWorkout from "@/model/DbWorkout";
import ListWorkoutChildren from "@/components/workout/ListWorkoutChildren.vue";
import workoutRepository from "@/repositories/workoutRepository";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    name: "Workout",
    components: {
        HeaderView,
        ListWorkoutChildren,
        FormDialog,
    },
    props: {
        id: {
            type: String,
        },
    },
    data(): {
        item: DbWorkout | null;
    } {
        return {
            item: null,
        };
    },
    async created() {
        workoutRepository.observeWorkout(
            this.id,
            (next) => {
                if (next == undefined) {
                    this.$router.push("/workouts");
                }
                this.item = next ?? null;
            },
            observeErrorHandler
        );
    },
    computed: {
        itemTitle(): string {
            return this.item?.title[Language.Default] ?? "Unknown title";
        }
    },
    methods: {
        addItems(): void {
            let params = {
                steps: [
                    {
                        title: this.$t("text.addChildrenToWorkout"),
                        component: "workout/AddChildrenToWorkoutForm",
                    },
                ],
                data: {
                    id: this.id,
                },
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async openEditDialog() {
            const item = this.item;
            if (item == undefined) return;
            let params = {
                steps: [
                    {
                        title: this.$t("text.editWorkout"),
                        component: "workout/WorkoutForm",
                    },
                ],
                data: item,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async openParentsListDialog() {
            const item = this.item;
            if (item == undefined) return;
            let params = {
                steps: [
                    {
                        title: this.$t("text.parentsList"),
                        component: "workout/WorkoutParentsList",
                    },
                ],
                data: {
                    childId: item.id
                },
                options: {
                    persistent: false,
                }
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async deleteWorkout(): Promise<void> {
            const item = this.item;
            if (item == undefined) return;
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.delete.item.title`),
                    this.$t(`confirm.delete.item.message`, {
                        name: item.title[Language.Default],
                    })
                )
            ) {
                await this.$router.push("/workouts");
                await workoutRepository.removeWorkout(item.id).catch(persistenceErrorHandler);
            }
        },
    },
});
