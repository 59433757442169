import ConfirmDialog from "./components/ConfirmDialog.vue";
import _Vue from "vue";

export default {
    install(Vue: typeof _Vue): void {
        Vue.component("confirm-dialog-vuetify", ConfirmDialog);
    },
};

export {ConfirmDialog};
