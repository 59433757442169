
import elementsMixin from "./elementsMixin";
import formElementsUtils from "./formElementsUtils";
import Vue, {defineComponent} from "vue";

export default defineComponent({
    name: "textField",
    mixins: [elementsMixin],
    mounted() {
        if (!this.model[this.element] && this.field.preCompiled) {
            if (typeof this.field.preCompiled == "function") {
                this.$set(this.model, this.getModelKeyPath(), this.field.preCompiled());
            }
        }
    },
    methods: {
        getModelKeyPath() {
            return formElementsUtils.getComputedKeyPath(
                this.element,
                this.model
            );
        },
        setComputedBoundValue(value) {
            const keyPath = this.getModelKeyPath();
            Vue.set(this.model, keyPath, value);
            this.$emit('input', this.model)
        },
    },
});
