
import {defineComponent} from "vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import HeaderView from "@/components/layout/HeaderView.vue";
import ListWorkouts from "@/components/workouts/ListWorkouts.vue";

export default defineComponent({
    name: "Exercises",
    components: {
        ListWorkouts,
        HeaderView,
        FormDialog,
    },
    methods: {
        openNewItemDialog() {
            let data = {};
            let params = {
                steps: [
                    {
                        title: this.$t("text.newWorkout"),
                        component: "workout/WorkoutForm",
                    },
                ],
                data,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
    },
});
