
import elementsMixin from "./elementsMixin";
import Vue, {defineComponent, VueConstructor} from "vue";

export default defineComponent({
    name: "checkBox",
    mixins: [elementsMixin],
    methods: {
        onInput(newValue) {
            Vue.set(this.model, this.element, newValue);
            this.$emit('input', this.model)
        }
    }
});
