import firestoreRepository from "./firestoreRepository";
import {Unsubscribe} from "@/repositories/common";

const COLLECTION_NAME = "settings";
const PROGRAM_TAGS_KEY = "program_tags";
const WORKOUT_TAGS_KEY = "workout_tags";
const EXERCISE_TAGS_KEY = "exercise_tags";
const PAGE_TAGS_KEY = "page_tags";

type ObserveTagsOnNext = (result: string[]) => void
type ObserveTagsOnError = (error: { code: string; message: string }) => void
const observeTags = (
    key: string,
    onNext: (result: string[]) => void,
    onError?: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return firestoreRepository.observe(
        COLLECTION_NAME,
        key,
        (next) => {
            if (next == null) {
                onNext([]);
            } else {
                onNext(
                    Object.entries(next.value)
                        .filter(([_, value]) => (value as any).count > 0)
                        .map(([key, _]) => key)
                );
            }
        },
        (error) => {
            onError?.(error);
        }
    );
};
const observeProgramTags =
    (onNext: ObserveTagsOnNext, onError: ObserveTagsOnError) => observeTags(PROGRAM_TAGS_KEY, onNext, onError);
const observeWorkoutTags =
    (onNext: ObserveTagsOnNext, onError: ObserveTagsOnError) => observeTags(WORKOUT_TAGS_KEY, onNext, onError);
const observeExerciseTags =
    (onNext: ObserveTagsOnNext, onError: ObserveTagsOnError) => observeTags(EXERCISE_TAGS_KEY, onNext, onError);
const observePageTags =
    (onNext: ObserveTagsOnNext, onError: ObserveTagsOnError) => observeTags(PAGE_TAGS_KEY, onNext, onError);

export default {
    COLLECTION_NAME,
    PROGRAM_TAGS_KEY,
    WORKOUT_TAGS_KEY,
    EXERCISE_TAGS_KEY,
    PAGE_TAGS_KEY,
    observeProgramTags,
    observeWorkoutTags,
    observeExerciseTags,
    observePageTags
};
