import firestoreRepository from "./firestoreRepository";
import _ from "lodash-es";
import {QueryConstraint, where} from "firebase/firestore";
import DbStorefront from "@/model/DbStorefront";
import workoutRepository from "@/repositories/workoutRepository";
import {ShelfItem} from "@/model/ShelfItem";
import programRepository from "@/repositories/programRepository";
import DbShelf from "@/model/DbShelf";
import DbShelfItem from "@/model/DbShelfItem";
import {Unsubscribe} from "@/repositories/common";
import Language from "@/model/Language";
import DbShelfItemType from "@/model/DbShelfItemType";
import {minifyLocalized} from "@/model/Localized";
import pageRepository from "@/repositories/pageRepository";
import { v4 as uuidv4 } from 'uuid';

const COLLECTION_NAME = "storefronts";

const MAIN_PROD_STOREFRONT_KEY = "main_prod"
const MAIN_TEST_STOREFRONT_KEY = "main_staging"

const findStorefronts = async (
    options: { itemId?: string, isPrivate?: boolean } = {}
): Promise<DbStorefront[]> => {
    const queryConstraints: QueryConstraint[] = [];
    if (options.itemId != undefined) {
        queryConstraints.push(
            where("itemsIds", "array-contains", options.itemId)
        );
    }
    if (options.isPrivate != undefined) {
        queryConstraints.push(
            where("isPrivate", "==", options.isPrivate)
        );
    }
    return await firestoreRepository.findAll(COLLECTION_NAME, {
        queryConstraints: queryConstraints,
    });
};
const observeStorefront = (
    id: string,
    onNext: (result?: DbStorefront) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return firestoreRepository.observe(COLLECTION_NAME, id, onNext, onError);
};

async function getStorefrontItem(el: DbShelfItem, pos: number): Promise<ShelfItem> {
    switch (el.itemType) {
        case DbShelfItemType.Workout: {
            const item = await workoutRepository.findWorkout(el.itemId)
            if (item == null) {
                throw "Invalid state, workout not found";
            } else {
                return {
                    relationId: el.relationId,
                    itemId: el.itemId,
                    position: pos,
                    isPremium: el.isPremium,
                    itemType: el.itemType,
                    item: item
                }
            }
        }
        case DbShelfItemType.Program: {
            const item = await programRepository.findProgram(el.itemId)
            if (item == null) {
                throw "Invalid state, program not found";
            } else {
                return {
                    relationId: el.relationId,
                    itemId: el.itemId,
                    position: pos,
                    isPremium: el.isPremium,
                    itemType: el.itemType,
                    item: item
                }
            }
        }
        case DbShelfItemType.Page: {
            const item = await pageRepository.findPage(el.itemId)
            if (item == null) {
                throw "Invalid state, page not found";
            } else {
                return {
                    relationId: el.relationId,
                    itemId: el.itemId,
                    position: pos,
                    isPremium: el.isPremium,
                    itemType: el.itemType,
                    item: item
                }
            }
        }
    }
}

const observeShelfItems = (
    storefrontId: string,
    shelfId: string,
    onNext: (result: ShelfItem[]) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return observeStorefront(
        storefrontId,
        async result => {
            const shelf = result?.shelves?.find(el => el.id == shelfId)
            if (shelf == undefined) {
                onNext([]);
                return;
            }
            const items: Promise<ShelfItem>[] = shelf.items.map(async (el, pos) => {
                return await getStorefrontItem(el, pos);
            })
            try {
                const ret = await Promise.all(items);
                onNext(ret);
            } catch (e) {
                onError(e as any)
            }
        },
        onError
    )
};
const addShelf = async (storefrontId: string, shelf: Omit<DbShelf, "items">): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Add shelf failed: shelfItem not found";
        }
        const newShelves = _.clone(storefrontData.shelves);
        newShelves.push({
            id: shelf.id,
            title: minifyLocalized(shelf.title),
            items: []
        })
        transaction.update(storefrontRef, {
            shelves: newShelves
        })
    })
}
const updateShelf = async (storefrontId: string, shelf: Omit<DbShelf, "items">): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Update shelf failed: shelfItem not found";
        }
        const newShelves = _.clone(storefrontData.shelves);
        const shelfToChange = newShelves.find(el => el.id == shelf.id);
        if (shelfToChange == undefined) {
            throw "Update shelf failed: shelf not found"
        }
        shelfToChange.title = minifyLocalized(shelf.title)

        transaction.update(storefrontRef, {
            shelves: newShelves
        })
    })
}
const moveShelf = async (
    storefrontId: string,
    oldIndex: number,
    newIndex: number
): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const item = await transaction.get(storefrontRef)
        const itemData = item?.data();
        if (itemData == null) {
            throw "Move shelf failed: shelfItem not found";
        }
        if (oldIndex >= itemData.shelves.length) {
            throw "Shelf not found"
        }

        const newShelves = _.clone(itemData.shelves)

        const movedShelf = newShelves.splice(oldIndex, 1)[0];
        newShelves.splice(newIndex, 0, movedShelf);

        await transaction.update(
            storefrontRef,
            {
                shelves: newShelves
            }
        )
    });
};
const addItemsToShelf = async (storefrontId: string, shelfId: string, items: Omit<DbShelfItem, "relationId" | "isPremium">[]): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Add items to shelf failed: shelfItem not found";
        }
        const newShelves = storefrontData.shelves;
        const shelfToChange = newShelves.find(el => el.id == shelfId);
        if (shelfToChange == undefined) {
            throw "Add items to shelf failed: shelf not found"
        }
        for (const item of items) {
            shelfToChange.items.push({
                itemId: item.itemId,
                itemType: item.itemType,
                isPremium: false,
                relationId: uuidv4()
            })
        }
        const newItemsIds = getItemsIds(newShelves)

        transaction.update(storefrontRef, {
            shelves: newShelves,
            itemsIds: newItemsIds
        })
    })
}
const moveShelfItem = async (
    storefrontId: string,
    shelfId: string,
    oldIndex: number,
    newIndex: number
): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const item = await transaction.get(storefrontRef)
        const itemData = item?.data();
        if (itemData == null) {
            throw "Move shelf item failed: storefront not found";
        }
        const newShelves = _.clone(itemData.shelves);
        const shelfToChange = newShelves.find(el => el.id == shelfId);
        if (shelfToChange == null) {
            throw "Move shelf item failed: shelf not found";
        }
        if (oldIndex >= shelfToChange.items.length) {
            throw "Move shelf item failed: shelf item not found";
        }

        const movedShelfItem = shelfToChange.items.splice(oldIndex, 1)[0];
        shelfToChange.items.splice(newIndex, 0, movedShelfItem);

        await transaction.update(
            storefrontRef,
            {
                shelves: newShelves
            }
        )
    });
};
const updateShelfItem = async (
    storefrontId: string,
    shelfId: string,
    item: { relationId: string; isPremium: boolean; }
): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Update shelf item failed: shelfItem not found";
        }
        const newShelves = storefrontData.shelves;
        const shelfToChange = newShelves.find(el => el.id == shelfId);
        if (shelfToChange == undefined) {
            throw "Update shelf item failed: shelf not found"
        }
        const itemToChange = shelfToChange.items.find(el => el.relationId == item.relationId);
        if (itemToChange == undefined) {
            throw "Update shelf item failed: shelf item not found"
        }
        itemToChange.isPremium = item.isPremium;

        transaction.update(storefrontRef, {
            shelves: newShelves
        })
    })
}
const removeItemFromShelf = async (storefrontId: string, shelfId: string, relationId: string): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Remove item from shelf failed: shelfItem not found";
        }
        const newShelves = storefrontData.shelves;
        const shelfToChange = newShelves.find(el => el.id == shelfId);
        if (shelfToChange == undefined) {
            throw "Remove item from shelf failed: shelf not found"
        }
        const childIndex = shelfToChange.items.findIndex(el => el.relationId == relationId);
        if (childIndex == -1) {
            // Child already removed, nothing to do
            return;
        }
        shelfToChange.items.splice(childIndex, 1);

        const newItemsIds = getItemsIds(newShelves)

        transaction.update(storefrontRef, {
            shelves: newShelves,
            itemsIds: newItemsIds
        })
    })
}
const removeShelf = async (storefrontId: string, shelfId: string): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Remove shelf failed: storefront not found";
        }
        const newShelves = _.clone(storefrontData.shelves);
        const shelfToRemoveIndex = newShelves.findIndex(el => el.id == shelfId);
        if (shelfToRemoveIndex == -1) {
            // Shelf already removed
            return;
        }
        const newItemsIds = getItemsIds(newShelves)

        newShelves.splice(shelfToRemoveIndex, 1)
        transaction.update(storefrontRef, {
            shelves: newShelves,
            itemsIds: newItemsIds
        })
    })
}

function getItemsIds(shelves: DbShelf[]): string[] {
    const itemsIds = shelves
        .flatMap(shelf => shelf.items.map(item => item.itemId))
    return _.uniq(itemsIds)
}

async function checkAllStorefrontItemsArePublic(storefront: DbStorefront) {
    const storefrontItems = storefront.shelves.flatMap(el => el.items);
    for (const storefrontItem of storefrontItems) {
        const fullItem = await getStorefrontItem(storefrontItem, -1);
        if (fullItem.item.isPrivate) {
            throw `You can't publish a storefront with private items (${fullItem.item.title[Language.Default]})`
        }
    }
}

const publishToProdStorefront = async (storefrontId: string): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const storefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, storefrontId)
        const storefront = await transaction.get(storefrontRef);
        const storefrontData = storefront.data();
        if (storefrontData == null) {
            throw "Publish to prod failed: storefront not found";
        }
        await checkAllStorefrontItemsArePublic(storefrontData);
        const prodStorefrontRef = firestoreRepository.documentReference<DbStorefront>(COLLECTION_NAME, MAIN_PROD_STOREFRONT_KEY)

        transaction.update(prodStorefrontRef, {
            shelves: storefrontData.shelves,
            itemsIds: storefrontData.itemsIds
        })
    })
};

export default {
    COLLECTION_NAME,
    MAIN_TEST_STOREFRONT_KEY,
    findStorefronts,
    observeStorefront,
    observeShelfItems,
    addShelf,
    updateShelf,
    moveShelf,
    removeShelf,
    addItemsToShelf,
    moveShelfItem,
    updateShelfItem,
    removeItemFromShelf,
    publishToProdStorefront
};
