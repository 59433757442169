
import {mapGetters} from "vuex";

export default {
    name: "ListItemsMenu",
    data() {
        return {
            items: [
                {
                    title: "word.storefront",
                    icon: "mdi-store",
                    route: "/storefront",
                },
                {
                    title: "word.pages",
                    icon: "mdi-file-multiple",
                    route: "/pages",
                },
                {
                    title: "word.programs",
                    icon: "mdi-calendar-text",
                    route: "/programs",
                },
                {
                    title: "word.workouts",
                    icon: "mdi-clipboard-list",
                    route: "/workouts",
                },
                {
                    title: "word.exercises",
                    icon: "mdi-dumbbell",
                    route: "/exercises",
                }
            ],
            right: null,
        };
    },

    computed: {
        ...mapGetters("user", {
            userProfile: "userProfile",
        }),
    },
};
