import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import Login from "@/views/Login.vue";
import Exercises from "@/views/Exercises.vue";
import auth from "./auth";
import store from "./store";
import Programs from "@/views/Programs.vue";
import Workouts from "@/views/Workouts.vue";
import Workout from "@/views/Workout.vue";
import Pages from "@/views/Pages.vue";
import Program from "@/views/Program.vue";
import Storefront from "@/views/Storefront.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: "*",
        component: () =>
            import(/* webpackChunkName: "about" */ "./views/Error404.vue"),
    },

    {path: "/", redirect: "/exercises"},
    {
        path: "/login",
        name: "Login",
        component: Login,
    },

    {
        path: "/pages",
        name: "Pages",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Pages,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/exercises",
        name: "Exercises",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Exercises,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/workouts",
        name: "Workouts",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Workouts,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/programs",
        name: "Programs",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Programs,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/workouts/:id",
        name: "Workout",
        meta: {
            requiresAuth: true,
        },
        component: Workout,
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/programs/:id",
        name: "Program",
        meta: {
            requiresAuth: true,
        },
        component: Program,
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: '/storefront',
        name: 'StoreFront',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ './views/Storefront.vue')
        component: Storefront
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
    base: process.env.BASE_URL,
});

function routerAuth(requiresAuth, loggedIn, next) {
    if (requiresAuth && !loggedIn) {
        next("/login");
    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    let loggedIn = store.state.user.loggedIn;
    if (loggedIn === undefined) {
        (async () => {
            loggedIn = await auth.checkAuthStatus();
            routerAuth(requiresAuth, loggedIn, next);
        })();
    } else {
        routerAuth(requiresAuth, loggedIn, next);
    }
});

export default router;
