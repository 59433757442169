
import {defineComponent} from "vue";
import HeaderView from "@/components/layout/HeaderView.vue";
import Language from "@/model/Language";
import FormDialog from "@/components/dialog/FormDialog.vue";
import DbProgram from "@/model/DbProgram";
import ListProgramChildren from "@/components/program/ListProgramChildren.vue";
import programRepository from "@/repositories/programRepository";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    name: "Program",
    components: {
        ListProgramChildren,
        HeaderView,
        FormDialog,
    },
    props: {
        id: {
            type: String,
        },
    },
    data(): {
        item: DbProgram | null;
    } {
        return {
            item: null,
        };
    },
    async created() {
        programRepository.observeProgram(
            this.id,
            (next) => {
                if (next == undefined) {
                    this.$router.push("/programs");
                }
                this.item = next ?? null;
            },
            observeErrorHandler
        );
    },
    computed: {
        itemTitle(): string {
            return this.item?.title[Language.Default] ?? "Unknown title";
        }
    },
    methods: {
        addChildrenToItem(): void {
            const item = this.item;
            if (item == undefined) return;
            let params = {
                steps: [
                    {
                        title: this.$t("text.addChildrenToProgram"),
                        component: "program/AddChildrenToProgramForm",
                    },
                ],
                data: {
                    id: item.id,
                    isPrivate: item.isPrivate
                },
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async openEditItemDialog() {
            const item = this.item;
            if (item == undefined) return;
            let params = {
                steps: [
                    {
                        title: this.$t("text.editProgram"),
                        component: "program/ProgramForm",
                    },
                ],
                data: item,
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async deleteProgram(): Promise<void> {
            const item = this.item;
            if (item == undefined) return;
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.delete.item.title`),
                    this.$t(`confirm.delete.item.message`, {
                        name: item.title[Language.Default],
                    })
                )
            ) {
                await this.$router.push("/programs");
                Vue.showLoadingOverlay(true)
                await programRepository.removeProgram(item.id).catch(persistenceErrorHandler);
                Vue.showLoadingOverlay(false)
            }
        },
    },
});
