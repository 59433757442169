
import {defineComponent} from "vue";
import Language from "@/model/Language";
import {PropType} from "vue/types/options";
import {ProgramChild} from "@/model/ProgramChild";
import programRepository from "@/repositories/programRepository";
import unsubscribeMixin from "@/plugins/components/utils/persistence/unsubscribeMixin";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import workoutRepository from "@/repositories/workoutRepository";

export default defineComponent({
    name: "listProgramChildren",
    mixins: [unsubscribeMixin],
    props: {
        tableActions: {
            type: Array as PropType<("remove" | "edit" | "delete")[]>,
            default: () => ["delete"],
        },
        itemId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            tableLoading: false,
            buttonsForm: {
                captionNo: "buttons.actions.reset",
                captionYes: "buttons.actions.find",
            },
            headers: [
                {
                    text: "Position",
                    value: `position`,
                    align: "center",
                    sortable: false,
                    width: "50px"
                },
                {
                    text: "Title",
                    value: `child.title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Focus Areas", value: "child.focusAreas", sortable: false},
                {text: "Training Goals", value: "child.trainingGoals", sortable: false},
                {text: "Tags", value: "child.tags", sortable: false},
                {text: "Private", value: "child.isPrivate", sortable: false, align: "center", width: "70px"},
                {text: "Premium", value: "isPremium", sortable: false, align: "center", width: "70px"},
                {text: "Actions", value: "actions", sortable: false, align: "center", width: "100px"},
            ],
            children: new Array<ProgramChild>(),
        };
    },

    mounted() {
        this.loadItems()
    },

    methods: {
        addActions(next: ProgramChild[]) {
            if (this.tableActions.length > 0) {
                next.forEach((el: any) => {
                    el.actions = {};
                    for (const action of this.tableActions) {
                        el.actions[action] = true;
                    }
                });
            }
        },
        async onTogglePrivate(item: ProgramChild) {
            this.tableLoading = true;
            const newPrivateValue = !item.child.isPrivate;
            try {

                switch (item.childType) {
                    case "workout":
                        await workoutRepository.updateWorkout({
                            id: item.childId,
                            isPrivate: newPrivateValue
                        })
                        break;
                }
                item.child.isPrivate = newPrivateValue
            } catch (e) {
                persistenceErrorHandler(e)
            }
            this.tableLoading = false;
        },
        async loadItems() {
            this.tableLoading = true;
            this.unsubscribeAll()
            const unsubscribe = programRepository.observeProgramChildren(
                this.itemId,
                next => {
                    this.addActions(next);
                    this.children = next;
                    this.tableLoading = false;
                },
                error => {
                    observeErrorHandler(error)
                    this.tableLoading = false;
                }
            )
            this.unsubscribes.push(unsubscribe)
        },
        async removeChildFromProgram(item: ProgramChild) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.remove.item.title`),
                    this.$t(`confirm.remove.item.message`, {
                        name: item.child.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true;
                await programRepository
                    .removeChildFromProgram(this.itemId, item.relationId)
                    .catch(persistenceErrorHandler)
                this.tableLoading = false;
            }
        },
        async onTogglePremium(item: ProgramChild) {
            this.tableLoading = true;
            await programRepository.updateProgramChild(
                this.itemId,
                {
                    relationId: item.relationId,
                    isPremium: !item.isPremium
                }
            ).catch(persistenceErrorHandler)
            this.tableLoading = false;
        },
        openParentsListDialog(item: ProgramChild) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.parentsList"),
                        component: "workout/WorkoutParentsList",
                    },
                ],
                data: {
                    childId: item.childId
                },
                options: {
                    persistent: false,
                }
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async clickAction({item, event, oldIndex, newIndex}) {
            switch (event) {
                case "onActionRemove":
                    await this.removeChildFromProgram(item);
                    break;
                case "onActionListParents":
                    this.openParentsListDialog(item);
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onRowMoved":
                    this.tableLoading = true;
                    await programRepository
                        .moveProgramChild(this.itemId, oldIndex, newIndex)
                        .catch(persistenceErrorHandler);
                    this.tableLoading = false;
                    break;
            }
        },
    },
});
