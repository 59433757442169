import firestoreRepository from "./firestoreRepository";
import settingsRepository from "./settingsRepository";
import _ from "lodash-es";
import {DocumentData, increment, serverTimestamp,} from "firebase/firestore";
import DbExercise from "@/model/DbExercise";
import workoutRepository from "@/repositories/workoutRepository";
import {updateTagsCollection} from "@/repositories/common";
import {minifyLocalized} from "@/model/Localized";

const COLLECTION_NAME = "exercises";

const getNewExerciseId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};

const findExercise = async (id: string): Promise<DbExercise | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
const saveExercise = async (exercise: DbExercise, create: boolean): Promise<void> => {
    const oldExercise = await findExercise(exercise.id);
    await firestoreRepository.batch((writeBatch) => {
        // Update Tags
        if (exercise.tags != null) {
            updateTagsCollection(
                writeBatch,
                settingsRepository.EXERCISE_TAGS_KEY,
                oldExercise?.tags ?? [],
                exercise.tags
            );
        }
        const exerciseClone: DocumentData = _.clone(exercise);
        delete exerciseClone.id;
        if (exerciseClone.title != undefined) {
            exerciseClone.title = minifyLocalized(exerciseClone.title);
        }
        if (create) {
            exerciseClone.createdAt = serverTimestamp();
        } else {
            delete exerciseClone.createdAt;
        }
        const docRef = firestoreRepository.documentReference(
            COLLECTION_NAME,
            exercise.id
        );
        writeBatch.set(docRef, exerciseClone, {merge: true});
    });
};
const removeExercise = async (id: string): Promise<void> => {
    const item = await findExercise(id);
    if (item == undefined) return;
    const linkedWorkouts = await workoutRepository.findWorkouts({childId: id});
    if (linkedWorkouts.length > 0) {
        throw "You can't delete an exercise if it is linked with at least one workout."
    }
    await firestoreRepository.batch((writeBatch) => {

        const decrementTagsDoc = {};
        for (const tag of item.tags) {
            decrementTagsDoc[`value.${tag}.count`] = increment(-1);
        }

        // Decrement settings tags count
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.EXERCISE_TAGS_KEY
            ),
            decrementTagsDoc
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewExerciseId,
    saveExercise,
    findExercise,
    removeExercise,
};
