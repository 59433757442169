import firestoreRepository from "./firestoreRepository";
import settingsRepository from "./settingsRepository";
import _ from "lodash-es";
import {DocumentData, increment, QueryConstraint, serverTimestamp, where} from "firebase/firestore";
import DbWorkout, {DbWorkoutChildRelation} from "@/model/DbWorkout";
import {Unsubscribe, updateTagsCollection, validateTags} from "@/repositories/common";
import programRepository from "@/repositories/programRepository";
import storefrontRepository from "@/repositories/storefrontRepository";
import DbExercise from "@/model/DbExercise";
import exerciseRepository from "@/repositories/exerciseRepository";
import {WorkoutChild} from "@/model/WorkoutChild";
import {DbIdentifiable} from "@/model/DbIdentifiable";
import {minifyLocalized} from "@/model/Localized";
import Language from "@/model/Language";
import { v4 as uuidv4 } from 'uuid';

const COLLECTION_NAME = "workouts";

const getNewWorkoutId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};
const findWorkouts = async (
    options: { childId?: string } = {}
): Promise<DbWorkout[]> => {
    const queryConstraints: QueryConstraint[] = [];
    if (options.childId != undefined) {
        queryConstraints.push(
            where("childrenIds", "array-contains", options.childId)
        );
    }
    return await firestoreRepository.findAll(COLLECTION_NAME, {
        queryConstraints: queryConstraints,
    });
};
const observeWorkout = (
    id: string,
    onNext: (result?: DbWorkout) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return firestoreRepository.observe(COLLECTION_NAME, id, onNext, onError);
};

async function _getWorkoutItem(el: DbWorkoutChildRelation, pos: number): Promise<WorkoutChild> {
    let ret: WorkoutChild;
    switch (el.childType) {
        case "exercise": {
            const item = await exerciseRepository.findExercise(el.childId)
            if (item == null) {
                throw "Invalid state, exercise not found";
            } else {
                ret = {
                    childId: el.childId,
                    relationId: el.relationId,
                    position: pos,
                    childType: el.childType,
                    child: item
                }
            }
        }
    }
    return ret;
}

const observeWorkoutChildren = (
    id: string,
    onNext: (result: WorkoutChild[]) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return observeWorkout(id, async result => {
        if (result == undefined) {
            onNext([]);
            return;
        }
        const items: Promise<WorkoutChild>[] = result.children.map(async (el, pos) => {
            return await _getWorkoutItem(el, pos);
        })
        const ret = await Promise.all(items);
        onNext(ret);
    }, onError);
}
const findWorkout = async (id: string): Promise<DbWorkout | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
const _getWorkoutDuration = async (children: DbWorkoutChildRelation[]): Promise<number> => {
    const itemDurations = await Promise.all(children.map(async (el) => {
        const item = await _getWorkoutItem(el, -1);
        return item.child.video[Language.Default].duration
    }))
    return itemDurations.reduce((partialSum, a) => partialSum + a, 0)
}
const addChildrenToWorkout = async (itemId: string, children: DbExercise[]): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const workoutRef = firestoreRepository.documentReference<DbWorkout>(COLLECTION_NAME, itemId)
        const item = await transaction.get(firestoreRepository.documentReference<DbWorkout>(COLLECTION_NAME, itemId))
        const itemData = item?.data();
        if (itemData == null) {
            throw "Workout not found"
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        for (const child of children) {
            newChildren.push({
                relationId: uuidv4(),
                childId: child.id,
                childType: "exercise"
            })
            newChildrenIds.push(child.id)
        }
        await transaction.update(
            workoutRef,
            {
                duration: await _getWorkoutDuration(newChildren),
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    })
};
const moveWorkoutChild = async (
    itemId: string,
    oldIndex: number,
    newIndex: number
): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const workoutRef = firestoreRepository.documentReference<DbWorkout>(COLLECTION_NAME, itemId)
        const item = await transaction.get(workoutRef)
        const itemData = item?.data();
        if (itemData == null) {
            throw "Workout not found"
        }
        if (oldIndex >= itemData.children.length) {
            throw "Child not found"
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        const movedChild = newChildren.splice(oldIndex, 1)[0];
        newChildren.splice(newIndex, 0, movedChild);
        const movedChildId = newChildrenIds.splice(oldIndex, 1)[0];
        newChildrenIds.splice(newIndex, 0, movedChildId);

        await transaction.update(
            workoutRef,
            {
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    });
};
const removeChildFromWorkout = async (itemId: string, relationId: string): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const workoutRef = firestoreRepository.documentReference<DbWorkout>(COLLECTION_NAME, itemId)
        const item = await transaction.get(firestoreRepository.documentReference<DbWorkout>(COLLECTION_NAME, itemId))
        const itemData = item?.data();
        if (itemData == null) {
            throw "Workout not found"
        }
        const childIndex = itemData.children.findIndex(el => el.relationId == relationId);
        if (childIndex == -1) {
            // Child already deleted, nothing to do
            return;
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        newChildren.splice(childIndex, 1);
        newChildrenIds.splice(childIndex, 1);

        await transaction.update(
            workoutRef,
            {
                duration: await _getWorkoutDuration(newChildren),
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    })
};

async function checkNoPublicParents(itemId: string) {
    const linkedStorefronts = await storefrontRepository.findStorefronts({ itemId: itemId, isPrivate: false});
    if (linkedStorefronts.length > 0) {
        throw "Remove the workout from all public storefronts before setting it private"
    }
    const linkedPrograms = await programRepository.findPrograms({childId: itemId, isPrivate: false});
    if (linkedPrograms.length > 0) {
        throw "Remove the workout from all public programs before setting it private"
    }
}

const createWorkout = async (item: DbWorkout): Promise<void> => saveWorkout(item, true)
const updateWorkout = async (item: Partial<DbWorkout> & DbIdentifiable): Promise<void> =>
    saveWorkout(item, false)

const saveWorkout = async (item: Partial<DbWorkout> & DbIdentifiable, create: boolean): Promise<void> => {
    const oldItem = await findWorkout(item.id);
    // If it is being set private it must not be used in any public shelfItem or program
    if (oldItem != null && !oldItem.isPrivate && (item.isPrivate == true)) {
        await checkNoPublicParents(item.id);
    }
    await firestoreRepository.batch((writeBatch) => {
        // Update Tags
        if (item.tags != null) {
            updateTagsCollection(
                writeBatch,
                settingsRepository.WORKOUT_TAGS_KEY,
                oldItem?.tags ?? [],
                item.tags
            );
        }

        const itemClone: DocumentData = _.clone(item);
        delete itemClone.id;
        if (itemClone.title != undefined) {
            itemClone.title = minifyLocalized(itemClone.title);
        }
        if (itemClone.subtitle != undefined) {
            itemClone.subtitle = minifyLocalized(itemClone.subtitle);
        }
        if (itemClone.text != undefined) {
            itemClone.text = minifyLocalized(itemClone.text);
        }
        if (create) {
            itemClone.createdAt = serverTimestamp();
        } else {
            delete itemClone.createdAt;
        }
        const docRef = firestoreRepository.documentReference(
            COLLECTION_NAME,
            item.id
        );
        writeBatch.set(docRef, itemClone, {merge: true});
    });
};

async function checkNoParents(id: string) {
    const linkedPrograms = await programRepository.findPrograms({childId: id});
    if (linkedPrograms.length > 0) {
        throw "You can't delete a workout if it is linked with at least one program."
    }
    const linkedStorefronts = await storefrontRepository.findStorefronts({ itemId: id});
    if (linkedStorefronts.length > 0) {
        throw "You can't delete a workout if it is in a shelfItem."
    }
}

const removeWorkout = async (id: string): Promise<void> => {
    const item = await findWorkout(id);
    if (item == undefined) return;
    await checkNoParents(id);
    await firestoreRepository.batch((writeBatch) => {

        const decrementTagsDoc = {};
        for (const tag of item.tags) {
            decrementTagsDoc[`value.${tag}.count`] = increment(-1);
        }

        // Decrement settings tags count
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.WORKOUT_TAGS_KEY
            ),
            decrementTagsDoc
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewWorkoutId,
    createWorkout,
    updateWorkout,
    findWorkouts,
    observeWorkout,
    observeWorkoutChildren,
    addChildrenToWorkout,
    moveWorkoutChild,
    removeChildFromWorkout,
    findWorkout,
    removeWorkout,
};
