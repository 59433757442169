import Loading, {LoaderType} from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export type LoadingOverlayConfig = {
    fullPage?: boolean;
    container?: HTMLElement;
    loader?: LoaderType;
    color?: string;
    canCancel?: false;
};
const config: LoadingOverlayConfig = {
    fullPage: false,
    container: undefined,
    loader: "spinner",
    color: "#424242",
    canCancel: false,
};

export const LoadingOverlay = {
    install: (Vue) => {
        Vue.use(Loading);

        Vue.showloading = null;
        Vue.loadingCount = 0;
        Vue.showLoadingOverlay = (
            show: boolean,
            params?: LoadingOverlayConfig
        ) => {
            const paramsConfig = {...config, ...params};
            if (show) {
                if (Vue.showloading == null) {
                    Vue.showloading = Vue.$loading.show({
                        height: 50,
                        weight: 60,
                        container: paramsConfig.fullPage
                            ? null
                            : paramsConfig.container,
                        loader: paramsConfig.loader,
                        color: paramsConfig.color,
                    });
                }
                Vue.loadingCount++;
            }
            if (!show) {
                Vue.loadingCount = Math.max(Vue.loadingCount - 1, 0);
                if (Vue.showloading != null) {
                    Vue.showloading.hide();
                    Vue.showloading = null;
                }
            }
        };
    },
};
