
import {defineComponent, PropType} from "vue";
import ActionsTable from "./ActionsTable.vue";
import Sortable from "sortablejs";
import {DataTableHeader} from "vuetify";
import _ from "lodash-es";


export default defineComponent({
    name: "Table",
    components: {
        ActionsTable,
    },

    props: {
        itemKey: {
            type: String,
            default: "id",
        },
        items: {
            type: Array as PropType<Partial<Record<string, string>>[]>,
            default: () => [],
        },
        columns: {
            type: Array as PropType<DataTableHeader[]>,
            default: () => [],
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false,
        },
        showCustomFooterPagination: {
            type: Boolean,
            default: false,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        selectOnClick: {
            type: Boolean,
            default: false,
        },
        numberOfItemsPerPage: {
            type: Number,
            default: 10,
        },
        sortBy: {
            type: String,
            default: undefined,
        },
        sortDesc: {
            type: Boolean,
            default: undefined,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        sortableDataTable: {
            bind(el, binding, vnode) {
                const sortable = (vnode as any).context.sortable;
                if (sortable) {
                    const options: Sortable.Options = {
                        animation: 150,
                        onUpdate: function (event) {
                            // @ts-ignore
                            vnode.child.$emit("sorted", event);
                        },
                        revertOnSpill: true
                    };
                    Sortable.create(
                        el.getElementsByTagName("tbody")[0],
                        options
                    );
                }
            },
        },
    },

    data() {
        return {
            selected: [],
        };
    },

    watch: {
        selected(newSelected, oldSelected) {
            this.clickAction(newSelected, "onRowSelection");
        },
    },

    computed: {},

    methods: {
        $_tableItemSlots() {
            const excludedItems = ["item.actions"]
            return _.pickBy(this.$scopedSlots, (_, key) => key.startsWith("item.") && !excludedItems.includes(key))
        },
        onMoveItem(event) {
            const movedItem = this.items[0];
            this.$emit("onDataEvent", {
                event: "onRowMoved",
                item: movedItem,
                oldIndex: event.oldIndex,
                newIndex: event.newIndex,
            });
        },
        clickAction(item: any, event: string): void {
            this.$emit("onDataEvent", {item, event});
        },
        clickRowAction(item: any, row): void {
            if (this.selectOnClick) {
                row.select(true)
            }
            this.clickAction(item, "onRowClick");
        },
    },
});
