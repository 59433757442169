
import Vue, {defineComponent, VueConstructor} from "vue";
import Language from "@/model/Language";
import {PropType} from "vue/types/options";
import workoutRepository from "@/repositories/workoutRepository";
import {WorkoutChild} from "@/model/WorkoutChild";
import unsubscribeMixin from "@/plugins/components/utils/persistence/unsubscribeMixin";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";

export default defineComponent({
    name: "listWorkoutChildren",
    mixins: [unsubscribeMixin],
    props: {
        tableActions: {
            type: Array as PropType<("remove" | "edit" | "delete")[]>,
            default: () => ["edit", "delete"],
        },
        itemId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tableLoading: false,
            buttonsForm: {
                captionNo: "buttons.actions.reset",
                captionYes: "buttons.actions.find",
            },
            headers: [
                {
                    text: "Position",
                    value: `position`,
                    sortable: false,
                    width: "50px"
                },
                {
                    text: "Title",
                    value: `child.title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Focus Areas", value: "child.focusAreas", sortable: false},
                {text: "Training Goals", value: "child.trainingGoals", sortable: false},
                {text: "Tags", value: "child.tags", sortable: false},
                {text: "Actions", value: "actions", sortable: false, width: "100px"},
            ],
            children: new Array<WorkoutChild>(),
        };
    },

    mounted() {
        this.loadItems()
    },

    methods: {
        addActions(next: WorkoutChild[]) {
            if (this.tableActions.length > 0) {
                next.forEach((el: any) => {
                    el.actions = {};
                    for (const action of this.tableActions) {
                        el.actions[action] = true;
                    }
                });
            }
        },
        async loadItems() {
            this.tableLoading = true;
            this.unsubscribeAll()
            const unsubscribe = workoutRepository.observeWorkoutChildren(
                this.itemId,
                next => {
                    this.addActions(next);

                    this.children = next;
                    this.tableLoading = false;
                },
                error => {
                    observeErrorHandler(error)
                    this.tableLoading = false;
                }
            )
            this.unsubscribes.push(unsubscribe);

        },
        async removeChildFromWorkout(item: WorkoutChild) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.remove.item.title`),
                    this.$t(`confirm.remove.item.message`, {
                        name: item.child.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true;
                await workoutRepository
                    .removeChildFromWorkout(this.itemId, item.relationId)
                    .catch(persistenceErrorHandler)
                this.tableLoading = false;
            }
        },
        async clickAction({item, event, oldIndex, newIndex}) {
            switch (event) {
                case "onActionRemove":
                    await this.removeChildFromWorkout(item);
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onRowMoved":
                    this.tableLoading = true;
                    await workoutRepository.moveWorkoutChild(this.itemId, oldIndex, newIndex).catch(persistenceErrorHandler);
                    this.tableLoading = false;
                    break;
            }
        },
    },
});
