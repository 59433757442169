import Vue from "vue";
import Vuelidate from "vuelidate";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {showMessage} from "./helpers/messages";
import env from "@/env";
import VueI18n from "vue-i18n";
import {DICTIONARY} from "./languages/en";
import FormBuilderVuetify from "./plugins/components/builders/form-builder-vuetify/src/main";
import TableBuilderVuetify from "./plugins/components/builders/table-builder-vuetify/src/main";
import PaginatedTableBuilderVuetify from "./plugins/components/builders/paginated-table-builder-vuetify/src/main";
import ConfirmDialogVuetify from "./plugins/components/ui/dialog/confirm-dialog-vuetify/src/main";
import {LoadingOverlay} from "./plugins/components/ui/loadingOverlay";
import {ShowMessagePayload} from "@/helpers/messages";

Vue.use(Vuelidate);

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "en", // set locale
    messages: DICTIONARY, // set locale messages
});

export class EventBus {
    private vue: Vue;

    constructor() {
        this.vue = new Vue();
    }

    emit(event: string, data: (Record<string, any> | null) = null): void {
        this.vue.$emit(event, data);
    }

    listen(event: string, callback): void {
        this.vue.$on(event, callback);
    }

    off(event: string, callback): void {
        this.vue.$off(event, callback);
    }
}
declare module 'vue/types/vue' {
    // 3. Declare augmentation for Vue
    interface Vue {
        $message: (payload: ShowMessagePayload) => void;
        $eventBus: EventBus;
    }
}

Vue.prototype.$eventBus = new EventBus();

Vue.config.productionTip = false;
Vue.prototype.$message = showMessage;
Vue.prototype.$env = env;

Vue.use(FormBuilderVuetify);
Vue.use(TableBuilderVuetify);
Vue.use(PaginatedTableBuilderVuetify);
Vue.use(ConfirmDialogVuetify);

Vue.use(LoadingOverlay);

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
