
import {defineComponent} from "vue";
import {useFormBuilder} from "@/components/form/formBuilderMixin";
import filters from "./filters";
import Language from "@/model/Language";
import {FindOptionsWhereFilter} from "@/repositories/firestoreRepository";
import _ from "lodash-es";
import DbWorkout from "@/model/DbWorkout";
import workoutRepository from "@/repositories/workoutRepository";
import {persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import {PropType} from "vue/types/options";

export default defineComponent({
    name: "ListWorkouts",
    props: {
        showSelect: {
            type: Boolean,
            default: false,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        showFilters: {
            type: Boolean,
            default: true,
        },
        customFilters: {
            type: Array as PropType<FindOptionsWhereFilter[]>,
            default: () => [],
        },
    },
    setup(props, context) {
        const formBuilder = useFormBuilder(props, context, filters);
        return {
            ...formBuilder,
        };
    },
    data() {
        return {
            tableLoading: false,
            localFilters: new Array<FindOptionsWhereFilter>(),
            headers: [
                {
                    text: "Title",
                    value: `title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Focus Areas", value: "focusAreas", sortable: false},
                {text: "Training Goals", value: "trainingGoals", sortable: false},
                {text: "Tags", value: "tags", sortable: false},
                {text: "Private", value: "isPrivate", sortable: false}
            ],
        };
    },
    computed: {
        filterQuery(): FindOptionsWhereFilter[] {
            return _.concat(this.localFilters, this.customFilters);
        }
    },

    methods: {
        filterItems(data) {
            const filterQuery: FindOptionsWhereFilter[] = [];

            const title = data?.filters?.title;
            if (title != undefined && title.length > 0) {
                filterQuery.push({
                    fieldPath: "title." + Language.Default,
                    opStr: "text-contains",
                    value: title,
                });
            }

            const tags = data?.filters?.tags;
            if (tags != undefined && tags.length > 0) {
                filterQuery.push({
                    fieldPath: "tags",
                    opStr: "array-contains-all",
                    value: tags,
                });
            }
            const focusAreas = data?.filters?.focusAreas;
            if (focusAreas != undefined && focusAreas.length > 0) {
                filterQuery.push({
                    fieldPath: "focusAreas",
                    opStr: "array-contains-all",
                    value: focusAreas,
                });
            }
            const trainingGoals = data?.filters?.trainingGoals;
            if (trainingGoals != undefined && trainingGoals.length > 0) {
                filterQuery.push({
                    fieldPath: "trainingGoals",
                    opStr: "array-contains-all",
                    value: trainingGoals,
                });
            }
            this.localFilters = filterQuery;
        },
        openWorkoutView(item: DbWorkout) {
            this.$router.push(`/workouts/${item.id}`);
        },
        async onTogglePrivate(item: DbWorkout) {
            this.tableLoading = true;
            await workoutRepository.updateWorkout({
                id: item.id,
                isPrivate: !item.isPrivate
            }).catch(persistenceErrorHandler)
            this.tableLoading = false;
        },
        openParentsListDialog(item: DbWorkout) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.parentsList"),
                        component: "workout/WorkoutParentsList",
                    },
                ],
                data: {
                    childId: item.id
                },
                options: {
                    persistent: false,
                }
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        clickAction({item, event}) {
            switch (event) {
                case "onActionListParents":
                    this.openParentsListDialog(item);
                    break;
                case "onRowClick":
                    if (!this.showSelect) {
                        this.openWorkoutView(item);
                    }
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
            }
        },
    },
});
