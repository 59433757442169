
import Vue, {defineComponent, VueConstructor} from "vue";
import Language from "@/model/Language";
import {PropType} from "vue/types/options";
import storefrontRepository from "@/repositories/storefrontRepository";
import {ShelfItem} from "@/model/ShelfItem";
import unsubscribeMixin from "@/plugins/components/utils/persistence/unsubscribeMixin";
import {observeErrorHandler, persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import DbShelfItemType from "@/model/DbShelfItemType";
import workoutRepository from "@/repositories/workoutRepository";
import programRepository from "@/repositories/programRepository";
import pageRepository from "@/repositories/pageRepository";

export default defineComponent({
    name: "ListShelfItems",
    mixins: [unsubscribeMixin],
    props: {
        tableActions: {
            type: Array as PropType<("remove" | "edit" | "delete")[]>,
            default: () => ["remove"],
        },
        storefrontId: {
            type: String,
            required: true,
        },
        shelfId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            tableLoading: false,
            buttonsForm: {
                captionNo: "buttons.actions.reset",
                captionYes: "buttons.actions.find",
            },
            headers: [
                {
                    text: "Position",
                    value: `position`,
                    sortable: false,
                    align: "center",
                    width: "50px"
                },
                {
                    text: "Title",
                    value: `item.title.${Language.Default}`,
                    sortable: false,
                },
                {
                    text: "Type",
                    value: `itemType`,
                    sortable: false,
                },
                {text: "Private", value: "item.isPrivate", sortable: false, align: "center", width: "70px"},
                {text: "Premium", value: "isPremium", sortable: false, align: "center", width: "70px"},
                {text: "Actions", value: "actions", sortable: false, align: "center", width: "70px"},
            ],
            shelfItems: Array<ShelfItem>(),
        };
    },
    mounted() {
        this.loadItems()
    },
    watch: {
        shelfId(_) {
            this.loadItems()
        }
    },
    methods: {
        addActions(next: ShelfItem[]) {
            if (this.tableActions.length > 0) {
                next.forEach((el: any) => {
                    el.actions = {};
                    for (const action of this.tableActions) {
                        el.actions[action] = true;
                    }
                });
            }
        },
        loadItems() {
            this.tableLoading = true;
            this.unsubscribeAll()
            this.shelfItems = []
            if (this.shelfId == undefined) return;
            const unsubscribe = storefrontRepository.observeShelfItems(
                this.storefrontId,
                this.shelfId,
                next => {
                    this.addActions(next);
                    this.shelfItems = next;
                    this.tableLoading = false;
                },
                error => {
                    observeErrorHandler(error)
                    this.tableLoading = false;
                }
            )
            this.unsubscribes.push(unsubscribe)
        },
        async removeItemFromShelf(item: ShelfItem) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.remove.item.title`),
                    this.$t(`confirm.remove.item.message`, {
                        name: item.item.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true;
                await storefrontRepository.removeItemFromShelf(
                    this.storefrontId,
                    this.shelfId,
                    item.relationId
                ).catch(persistenceErrorHandler)
                this.tableLoading = false;
            }
        },
        async onTogglePrivate(item: ShelfItem) {
            this.tableLoading = true;
            const newPrivateValue = !item.item.isPrivate;
            try {

                switch (item.itemType) {
                    case DbShelfItemType.Workout:
                        await workoutRepository.updateWorkout({
                            id: item.item.id,
                            isPrivate: newPrivateValue
                        })
                        break;
                    case DbShelfItemType.Program:
                        if (
                            // Show alert only if it will be set as public
                            !newPrivateValue &&
                            !(await (this.$refs.confirm as any).open(
                                this.$t(`confirm.publish.program.title`),
                                this.$t(`confirm.publish.program.message`)
                            ))
                        ) {
                            this.tableLoading = false;
                            return;
                        }
                        await programRepository.updateProgram({
                            id: item.item.id,
                            isPrivate: newPrivateValue
                        })
                        break
                    case DbShelfItemType.Page:
                        await pageRepository.updatePage({
                            id: item.item.id,
                            isPrivate: newPrivateValue
                        })
                        break;
                }
                item.item.isPrivate = newPrivateValue
            } catch (e) {
                persistenceErrorHandler(e)
            }
            this.tableLoading = false;
        },
        async onTogglePremium(item: ShelfItem) {
            this.tableLoading = true;
            await storefrontRepository.updateShelfItem(
                this.storefrontId,
                this.shelfId,
                {
                    relationId: item.relationId,
                    isPremium: !item.isPremium
                }
            ).catch(persistenceErrorHandler)
            this.tableLoading = false;
        },
        async clickAction({item, event, oldIndex, newIndex}) {
            switch (event) {
                case "onActionRemove":
                    await this.removeItemFromShelf(
                        item
                    )
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onRowMoved":
                    this.tableLoading = true;
                    await storefrontRepository.moveShelfItem(
                        this.storefrontId,
                        this.shelfId,
                        oldIndex,
                        newIndex
                    ).catch(persistenceErrorHandler);
                    this.tableLoading = false;
                    break;
            }
        },
    },
});
