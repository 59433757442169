// noinspection SpellCheckingInspection

export default {
    MODE: "prod",
    NAME_SPACE: "backoffice",
    RECAPTCHA_SITE_KEY: "6LdDQ8cfAAAAAIVnTrjVUgnaqhoY2HV2KEKpUbR0",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyBzwjGVXPT_eQWypv4AeIzz9AXpUrqfe5g",
        authDomain: "facegym-prod.firebaseapp.com",
        projectId: "facegym-prod",
        storageBucket: "facegym-prod.appspot.com",
        messagingSenderId: "396531629187",
        appId: "1:396531629187:web:8adbe6c8b071ca785defb6",
        measurementId: "G-1K2QR90D68"
    },
};
