
import {defineComponent} from "vue";
import filters from "./filters";
import Language from "@/model/Language";
import {FindOptionsWhereFilter} from "@/repositories/firestoreRepository";
import _ from "lodash-es";
import {PropType} from "vue/types/options";
import pageRepository from "@/repositories/pageRepository";
import {persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import DbPage from "@/model/DbPage";
import {useFormBuilder} from "@/components/form/formBuilderMixin";

export default defineComponent({
    name: "ListPages",
    props: {
        showSelect: {
            type: Boolean,
            default: false,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        tableActions: {
            type: Array as PropType<("edit" | "delete")[]>,
            default: () => ["edit", "delete"],
        },
        showFilters: {
            type: Boolean,
            default: true,
        },
        customFilters: {
            type: Array as PropType<FindOptionsWhereFilter[]>,
            default: () => [],
        },
    },
    data() {
        return {
            tableLoading: false,
            localFilters: new Array<FindOptionsWhereFilter>(),
            headers: [
                {
                    text: "Title",
                    value: `title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Tags", value: "tags", sortable: false},
                {text: "Private", value: "isPrivate", sortable: false},
                {text: "Actions", value: "actions", sortable: false, width: "130px"}
            ],
        };
    },
    setup(props, context) {
        const formBuilder = useFormBuilder(props, context, filters);
        return {
            ...formBuilder,
        };
    },
    computed: {
        filterQuery(): FindOptionsWhereFilter[] {
            return _.concat(this.localFilters, this.customFilters);
        }
    },

    methods: {
        filterItems(data) {
            const filterQuery: FindOptionsWhereFilter[] = [];

            const title = data?.filters?.title;
            if (title != undefined && title.length > 0) {
                filterQuery.push({
                    fieldPath: "title." + Language.Default,
                    opStr: "text-contains",
                    value: title,
                });
            }

            const tags = data?.filters?.tags;
            if (tags != undefined && tags.length > 0) {
                filterQuery.push({
                    fieldPath: "tags",
                    opStr: "array-contains-all",
                    value: tags,
                });
            }
            this.localFilters = filterQuery;
        },
        async onTogglePrivate(item: DbPage) {
            const newPrivateValue = !item.isPrivate
            this.tableLoading = true;
            await pageRepository.updatePage({
                id: item.id,
                isPrivate: newPrivateValue
            }).catch(persistenceErrorHandler);
            this.tableLoading = false;
        },

        async removeItem(item: DbPage) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.delete.item.title`),
                    this.$t(`confirm.delete.item.message`, {
                        name: item.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true;
                await pageRepository.removePage(item.id).catch(persistenceErrorHandler);
                this.tableLoading = false;
            }
        },

        openEditItemDialog(item: DbPage) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.editPage"),
                        component: "page/PageForm",
                    },
                ],
                data: {init: item},
            };

            this.$eventBus.emit("showFormDialog", {params, show: true});
        },

        clickAction({item, event}) {
            switch (event) {
                case "onActionDelete":
                    this.removeItem(item);
                    break;
                case "onActionEdit":
                    this.openEditItemDialog(item);
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
            }
        },
    },
});
