
import {validationMixin} from "vuelidate";
import TextField from "./elements/TextField.vue";
import TextArea from "./elements/TextArea.vue";
import CheckBox from "./elements/CheckBox.vue";
import ColorField from "./elements/ColorField.vue";
import Spacer from "./elements/Spacer.vue";
import CSelect from "./elements/CSelect.vue";
import ComboBox from "./elements/ComboBox.vue";
import FileUpload from "./elements/FileUpload.vue";
import ComboChips from "./elements/ComboChips.vue";
import {defineComponent} from "vue";
import _ from "lodash-es";

const ERROR_CONFIG = {
    required: "help is-danger",
};

export default defineComponent({
    name: "FormBuilder",
    mixins: [validationMixin],
    props: {
        title: {
            type: String,
            default: "Title not set",
        },
        initialValues: {
            type: Object,
            default: null,
        },
        configForm: {
            type: Array,
            default() {
                return [];
            },
        },
        hideButtons: {
            type: Boolean,
            default: false
        },
        buttons: {
            type: Object,
            default() {
                return {
                    captionNo: "No",
                    captionYes: "Yes",
                };
            },
        },
        submitButtonLoading: {
            type: Boolean,
            default: false
        },
        validation: {
            type: Object,
            default: null,
        },
        resetForm: {
            type: Boolean,
            default: false,
        },
        actionsDisabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TextField,
        TextArea,
        ColorField,
        CheckBox,
        FileUpload,
        ComboChips,
        ComboBox,
        CSelect,
        Spacer,
    },
    data() {
        return {
            previousFormValue: {},
            formValues: {},
            files: {},
            disabled: false,
            errorConfig: ERROR_CONFIG,
        };
    },

    validations() {
        return {
            formValues: this.rules,
        };
    },
    created() {
        const flattenedInitialValues = flatten(this.initialValues)
        this.formValues = flattenedInitialValues;
        this.previousFormValue = _.cloneDeep(flattenedInitialValues);
    },
    computed: {
        rules() {
            return (<any>this.$props).validation;
        },

        hiddenField() {
            return (field) => {
                let result = false;

                if (field.hidden) {
                    result = field.hidden;
                }

                if (!result) {
                    if (field.show) {
                        let isVisible = true;
                        for (const showItem of field.show) {
                            if (this.formValues[showItem.element]) {
                                isVisible =
                                    isVisible &&
                                    (showItem.values.includes(
                                            this.formValues[showItem.element].value
                                        ) ||
                                        showItem.values.includes(
                                            this.formValues[showItem.element]
                                        ));
                            } else {
                                isVisible = false;
                            }
                        }
                        result = !isVisible;
                    }
                }

                return result;
            };
        },
    },
    methods: {
        setOptions(field) {
            return field.options ? field.options : {};
        },

        setComponentEvent({action, data}) {
            this.$emit("onComponentEvent", {action, data});
        },

        setColumnLength(field) {
            if (Object.prototype.hasOwnProperty.call(field, "col")) {
                return field.col;
            } else {
                return 12;
            }
        },
        hasOnChangeListener() {
            return this.$listeners && this.$listeners.onChangeForm
        },
        onChange(newValue) {
            if (!this.hasOnChangeListener()) {
                // Avoid unnecessary overload if not using listener
                return;
            }
            if (this.$v.$invalid) {
                // Invalid values are not sent
                return;
            }
            if (_.isEqual(newValue, this.previousFormValue)) {
                // Do not notify if no change made
                return;
            }
            this.previousFormValue = _.cloneDeep(newValue);
            this.$emit("onChangeForm", unflatten(newValue));
        },
        onSubmit() {
            this.$emit("onSubmitForm", unflatten(this.formValues));
        },
        onClose() {
            this.$emit("onClose");
        },

        getErrorMessage(field, error) {
            let message = field.error[error];
            if (typeof message === "function") {
                message = message.call(
                    null,
                    this.$v.formValues?.[field.name]?.$params[error]
                );
            }
            return message;
        },
    },
});

// It doesn't flatten arrays
function flatten(data) {
    let result = {};

    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            result[prop] = cur;
        } else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    }

    recurse(data, "");
    return result;
}

function unflatten(data) {
    "use strict";
    if (Object(data) !== data || Array.isArray(data)) return data;
    const regex = /\.?([^.[\]]+)|\[(\d+)\]/g,
        resultholder = {};
    for (const p in data) {
        let cur = resultholder,
            prop = "",
            m;
        while ((m = regex.exec(p))) {
            cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[""] || resultholder;
}
