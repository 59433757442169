const mode = process.env.NODE_ENV || "development";
import developmentEnv from "./env/development";
import productionEnv from "./env/production";

const env = {
    development: developmentEnv,
    production: productionEnv,
};

export default env[mode];
