import settingsRepository from "@/repositories/settingsRepository";
import FocusArea from "@/model/FocusArea";
import TrainingGoal from "@/model/TrainingGoal";

export default {
    form: [
        {
            type: "textField",
            label: "Title",
            name: "filters.title",
            col: 6
        },
        {
            type: "comboChips",
            label: "Tags",
            name: "filters.tags",
            headerText: "components.combochips.header.search",
            actions: {
                create: false,
            },
            col: 6,
            items: settingsRepository.observeExerciseTags,
        },
        {
            type: "comboChips",
            label: "Focus Areas",
            name: "filters.focusAreas",
            headerText: "components.combochips.header.search",
            actions: {
                create: false,
            },
            col: 6,
            items: Object.keys(FocusArea)
                .map((itemValue) => {
                    return {
                        key: FocusArea[itemValue],
                        text: itemValue,
                    };
                }),
        },
        {
            type: "comboChips",
            label: "Training Goals",
            name: "filters.trainingGoals",
            headerText: "components.combochips.header.search",
            actions: {
                create: false,
            },
            col: 6,
            items: Object.keys(TrainingGoal)
                .map((itemValue) => {
                    return {
                        key: TrainingGoal[itemValue],
                        text: itemValue,
                    };
                }),
        }
    ],

    validate: {},
};
