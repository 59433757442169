import firestoreRepository from "./firestoreRepository";
import settingsRepository from "./settingsRepository";
import _ from "lodash-es";
import {DocumentData, increment, QueryConstraint, serverTimestamp, where,} from "firebase/firestore";
import {Unsubscribe, updateTagsCollection, validateTags} from "@/repositories/common";
import storefrontRepository from "@/repositories/storefrontRepository";
import DbProgram, {DbProgramChildRelation} from "@/model/DbProgram";
import DbWorkout from "@/model/DbWorkout";
import {ProgramChild} from "@/model/ProgramChild";
import workoutRepository from "@/repositories/workoutRepository";
import {DbIdentifiable} from "@/model/DbIdentifiable";
import {minifyLocalized} from "@/model/Localized";
import { v4 as uuidv4 } from 'uuid';

const COLLECTION_NAME = "programs";

const getNewProgramId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};
const findPrograms = async (
    options: { childId?: string, isPrivate?: boolean } = {}
): Promise<DbProgram[]> => {
    const queryConstraints: QueryConstraint[] = [];
    if (options.childId != undefined) {
        queryConstraints.push(
            where("childrenIds", "array-contains", options.childId)
        );
    }
    if (options.isPrivate != undefined) {
        queryConstraints.push(
            where("isPrivate", "==", options.isPrivate)
        );
    }
    return await firestoreRepository.findAll(COLLECTION_NAME, {
        queryConstraints: queryConstraints,
    });
};
const findProgram = async (id: string): Promise<DbProgram | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
const observeProgram = (
    id: string,
    onNext: (result?: DbProgram) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return firestoreRepository.observe(COLLECTION_NAME, id, onNext, onError);
};

async function getCompleteProgramChild(el: DbProgramChildRelation, pos: number): Promise<ProgramChild> {
    switch (el.childType) {
        case "workout": {
            const item = await workoutRepository.findWorkout(el.childId)
            if (item == null) {
                throw "Invalid state, workout not found";
            } else {
                return {
                    relationId: el.relationId,
                    childId: el.childId,
                    position: pos,
                    isPremium: el.isPremium,
                    childType: el.childType,
                    child: item
                }
            }
        }
    }
}

const observeProgramChildren = (
    id: string,
    onNext: (result: ProgramChild[]) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    return observeProgram(id, async result => {
        if (result == undefined) {
            onNext([]);
            return;
        }
        const items: Promise<ProgramChild>[] = result.children.map(async (el, pos) => {
            return await getCompleteProgramChild(el, pos);
        })
        const ret = await Promise.all(items);
        onNext(ret);
    }, onError);
}

async function checkNoPublicParents(itemId: string) {
    const linkedStorefronts = await storefrontRepository.findStorefronts({itemId: itemId, isPrivate: false});
    if (linkedStorefronts.length > 0) {
        throw "Remove the program from all the public storefronts before setting it private"
    }
}

const createProgram = async (item: DbProgram): Promise<void> => saveProgram(item, true)
const updateProgram = async (item: Partial<DbProgram> & DbIdentifiable): Promise<void> =>
    saveProgram(item, false)

const saveProgram = async (item: Partial<DbProgram> & DbIdentifiable, create: boolean): Promise<void> => {

    const oldItem = await findProgram(item.id);


    // If it is being set private it must not be used in any public shelfItem or program
    if (oldItem != null && !oldItem.isPrivate && (item.isPrivate == true)) {
        await checkNoPublicParents(item.id);
    }
    // If it is being set public all his child must be set to public
    if (oldItem != null && oldItem.isPrivate && (item.isPrivate == false)) {
        await setProgramChildrenPublic(oldItem.children);
    }

    await firestoreRepository.batch((writeBatch) => {
        // Update Tags if needed
        if (item.tags != null) {
            updateTagsCollection(
                writeBatch,
                settingsRepository.PROGRAM_TAGS_KEY,
                oldItem?.tags ?? [],
                item.tags
            );
        }
        const itemClone: DocumentData = _.clone(item);
        delete itemClone.id;
        if (itemClone.title != undefined) {
            itemClone.title = minifyLocalized(itemClone.title);
        }
        if (itemClone.subtitle != undefined) {
            itemClone.subtitle = minifyLocalized(itemClone.subtitle);
        }
        if (itemClone.text != undefined) {
            itemClone.text = minifyLocalized(itemClone.text);
        }
        if (itemClone.expectedResults != undefined) {
            itemClone.expectedResults = minifyLocalized(itemClone.expectedResults);
        }
        if (create) {
            itemClone.createdAt = serverTimestamp();
        } else {
            delete itemClone.createdAt;
        }
        const docRef = firestoreRepository.documentReference(
            COLLECTION_NAME,
            item.id
        );
        writeBatch.set(docRef, itemClone, {merge: true});
    });
};
const addChildrenToProgram = async (itemId: string, children: DbWorkout[]): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const programRef = firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId)
        const item = await transaction.get(firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId))
        const itemData = item?.data();
        if (itemData == null) {
            throw "Program not found"
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        for (const child of children) {
            newChildren.push({
                relationId: uuidv4(),
                childId: child.id,
                childType: "workout",
                isPremium: false
            })
            newChildrenIds.push(child.id)
        }
        await transaction.update(
            programRef,
            {
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    })
};

async function setProgramChildrenPublic(children: DbProgramChildRelation[]) {
    const promises = Array<Promise<void>>();
    for (const storefrontItem of children) {
        const fullItem = await getCompleteProgramChild(storefrontItem, -1);
        if (fullItem.child.isPrivate) {
            switch (fullItem.childType) {
                case "workout":
                    promises.push(workoutRepository.updateWorkout({
                        id: fullItem.childId,
                        isPrivate: false
                    }))
                    break;
            }

        }
    }
    return Promise.all(promises);
}

const updateProgramChild = async (itemId: string, child: { relationId: string; isPremium: boolean; }): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const workoutRef = firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId)
        const item = await transaction.get(firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId))
        const itemData = item?.data();
        if (itemData == null) {
            throw "Program not found"
        }
        const childIndex = itemData.children.findIndex(el => el.relationId == child.relationId);
        if (childIndex == -1) {
            throw "Child not found"
        }

        const newChildren = _.clone(itemData.children)

        const childrenToUpdate = newChildren[childIndex]
        newChildren[childIndex] = {
            relationId: child.relationId,
            childId: childrenToUpdate.childId,
            childType: childrenToUpdate.childType,
            isPremium: child.isPremium
        }
        await transaction.update(
            workoutRef,
            {
                children: newChildren
            }
        )
    })
};
const moveProgramChild = async (
    itemId: string,
    oldIndex: number,
    newIndex: number
): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const programRef = firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId)
        const item = await transaction.get(programRef)
        const itemData = item?.data();
        if (itemData == null) {
            throw "Program not found"
        }
        if (oldIndex >= itemData.children.length) {
            throw "Child not found"
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        const movedChild = newChildren.splice(oldIndex, 1)[0];
        newChildren.splice(newIndex, 0, movedChild);
        const movedChildId = newChildrenIds.splice(oldIndex, 1)[0];
        newChildrenIds.splice(newIndex, 0, movedChildId);

        await transaction.update(
            programRef,
            {
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    });
};
const removeChildFromProgram = async (itemId: string, relationId: string): Promise<void> => {
    await firestoreRepository.executeTransaction(async transaction => {
        const programRef = firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId)
        const item = await transaction.get(firestoreRepository.documentReference<DbProgram>(COLLECTION_NAME, itemId))
        const itemData = item?.data();
        if (itemData == null) {
            throw "Program not found"
        }
        const childIndex = itemData.children.findIndex(el => el.relationId == relationId);
        if (childIndex == -1) {
            // Child already deleted, nothing to do
            return;
        }

        const newChildren = _.clone(itemData.children)
        const newChildrenIds = _.clone(itemData.childrenIds)

        newChildren.splice(childIndex, 1);
        newChildrenIds.splice(childIndex, 1);

        await transaction.update(
            programRef,
            {
                children: newChildren,
                childrenIds: newChildrenIds
            }
        )
    })
};

async function checkNoParents(id: string) {
    const linkedStorefronts = await storefrontRepository.findStorefronts({itemId: id});
    if (linkedStorefronts.length > 0) {
        throw "You can't delete a program if it is in a shelfItem."
    }
}

const removeProgram = async (id: string): Promise<void> => {
    const item = await findProgram(id);
    if (item == undefined) return;
    await checkNoParents(id);
    await firestoreRepository.batch((writeBatch) => {

        const decrementTagsDoc = {};
        for (const tag of item.tags) {
            decrementTagsDoc[`value.${tag}.count`] = increment(-1);
        }

        // Decrement settings tags count
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.PROGRAM_TAGS_KEY
            ),
            decrementTagsDoc
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewProgramId,
    createProgram,
    updateProgram,
    findPrograms,
    findProgram,
    addChildrenToProgram,
    observeProgramChildren,
    updateProgramChild,
    moveProgramChild,
    removeChildFromProgram,
    observeProgram,
    removeProgram,
};
