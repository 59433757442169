enum FocusArea {
    Forehead = "forehead",
    Eyes = "eyes",
    Nose = "nose",
    Mouth = "mouth",
    Cheeks = "cheeks",
    Jawline = "jawline",
    Neck = "neck"
}

export default FocusArea;
