
import {defineComponent} from "vue";
import filters from "./filters";
import Language from "@/model/Language";
import {FindOptionsWhereFilter} from "@/repositories/firestoreRepository";
import _ from "lodash-es";
import {PropType} from "vue/types/options";
import DbExercise from "@/model/DbExercise";
import exerciseRepository from "@/repositories/exerciseRepository";
import {persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import {useFormBuilder} from "@/components/form/formBuilderMixin";

export default defineComponent({
    name: "listExercises",
    props: {
        showSelect: {
            type: Boolean,
            default: false,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        tableActions: {
            type: Array as PropType<("list_parents" | "remove" | "edit" | "delete")[]>,
            default: () => ["list_parents", "edit", "delete"],
        },
        customFilters: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            tableLoading: false,
            filterQuery: this.customFilters ?? [],
            headers: [
                {
                    text: "Title",
                    value: `title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Focus Areas", value: "focusAreas", sortable: false},
                {text: "Training Goals", value: "trainingGoals", sortable: false},
                {text: "Tags", value: "tags", sortable: false},
                {text: "Actions", value: "actions", sortable: false, width: "130px"},
            ],
        };
    },
    setup(props, context) {
        const formBuilder = useFormBuilder(props, context, filters);
        return {
            ...formBuilder,
        };
    },
    beforeMount() {
        this.filterQuery = this.customFilters;
    },

    methods: {
        filterItems(data) {
            const filterQuery: FindOptionsWhereFilter[] = [];

            const title = data?.filters?.title;
            if (title != undefined && title.length > 0) {
                filterQuery.push({
                    fieldPath: "title." + Language.Default,
                    opStr: "text-contains",
                    value: title,
                });
            }

            const tags = data?.filters?.tags;
            if (tags != undefined && tags.length > 0) {
                filterQuery.push({
                    fieldPath: "tags",
                    opStr: "array-contains-all",
                    value: tags,
                });
            }
            const focusAreas = data?.filters?.focusAreas;
            if (focusAreas != undefined && focusAreas.length > 0) {
                filterQuery.push({
                    fieldPath: "focusAreas",
                    opStr: "array-contains-all",
                    value: focusAreas,
                });
            }
            const trainingGoals = data?.filters?.trainingGoals;
            if (trainingGoals != undefined && trainingGoals.length > 0) {
                filterQuery.push({
                    fieldPath: "trainingGoals",
                    opStr: "array-contains-all",
                    value: trainingGoals,
                });
            }
            this.filterQuery = _.concat(filterQuery, this.customFilters);
        },

        openEditItemDialog(item: DbExercise) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.editExercise"),
                        component: "exercise/ExerciseForm",
                    },
                ],
                data: {init: item},
            };

            this.$eventBus.emit("showFormDialog", {params, show: true});
        },
        async openParentsListDialog(item: DbExercise) {
            let params = {
                steps: [
                    {
                        title: this.$t("text.parentsList"),
                        component: "exercise/ExerciseParentsList",
                    },
                ],
                data: {
                    childId: item.id
                },
                options: {
                    persistent: false,
                }
            };
            this.$eventBus.emit("showFormDialog", {params, show: true});
        },

        async removeItem(item: DbExercise) {
            if (
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.delete.item.title`),
                    this.$t(`confirm.delete.item.message`, {
                        name: item.title[Language.Default],
                    })
                )
            ) {
                this.tableLoading = true;
                await exerciseRepository.removeExercise(item.id).catch(persistenceErrorHandler);
                this.tableLoading = false;
            }
        },

        clickAction({item, event}) {
            switch (event) {
                case "onActionRemove":
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onActionEdit":
                    this.openEditItemDialog(item);
                    break;
                case "onActionListParents":
                    this.openParentsListDialog(item);
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
                case "onActionDelete":
                    this.removeItem(item);
            }
        },
    },
});
