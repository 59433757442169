
export default {
    name: "HeaderView",
    props: {
        title: {
            type: String,
            default: "Nome View Empty",
        },
        showBackButton: {
            type: Boolean,
            default: false,
        },
    },
};
