
import elementsMixin from "./elementsMixin";
import Vue, {defineComponent, VueConstructor} from "vue";

export default defineComponent({
    name: "ComboBox",
    mixins: [elementsMixin],

    mounted() {
        if (
            this.model[this.element] !== undefined &&
            !this.model[this.element].value
        ) {
            Vue.set(this.model, this.element, {
                ...this.field.items.find(
                    (item) => item.value === this.model[this.element]
                ),
            });
        }
    },
    methods: {
        onInput(newValue) {
            Vue.set(this.model, this.element, newValue);
            this.$emit('input', this.model)
        }
    }
});
