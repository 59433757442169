function getMatches(string: string, regex: RegExp, index: number) {
    index || (index = 1); // default to the first capturing group
    const matches: string[] = [];
    let match: RegExpExecArray | null;
    while ((match = regex.exec(string))) {
        matches.push(match?.[index]);
    }
    return matches;
}

function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
}

export default {
    getMatches,
    getEnumKeyByEnumValue
};
