import firebaseApp from "@/firebaseApp";
import {showMessage} from "@/helpers/messages";
import {getAuth, GoogleAuthProvider, signInWithPopup, signOut,} from "firebase/auth";

export default {
    namespaced: true,
    state: {
        userProfile: {},
        loggedIn: undefined,
    },
    getters: {
        userProfile: ({userProfile}) => userProfile,
        loggedIn: ({loggedIn}) => loggedIn,
    },
    mutations: {
        USER_DETAILS(state, userProfile) {
            state.loggedIn = true;
            state.userProfile = {
                name: userProfile.displayName,
                email: userProfile.email,
                picture: userProfile.photoURL,
                uid: userProfile.uid,
            };
        },
        LOGOUT(state) {
            state.loggedIn = false;
            state.userProfile = {};
        },
    },
    actions: {
        async login(store) {
            if (store.state.loggedIn) return;
            const firebaseAuth = getAuth(firebaseApp);
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });
            try {
                const userCredential = await signInWithPopup(
                    firebaseAuth,
                    provider
                );
                if (
                    userCredential.user?.email?.endsWith("@ulixe.app") !==
                    true
                ) {
                    showMessage({
                        text: "Access denied",
                        type: "error",
                    });
                    await signOut(firebaseAuth);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async logout(): Promise<void> {
            const firebaseAuth = getAuth(firebaseApp);
            try {
                await firebaseAuth.signOut();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
