import {showMessage} from "@/helpers/messages";
import {increment, WriteBatch} from "firebase/firestore";
import _ from "lodash-es";
import firestoreRepository from "@/repositories/firestoreRepository";
import settingsRepository from "@/repositories/settingsRepository";

export function validateTags(tags: string[]): boolean {
    for (const tag of tags) {
        if (!/^[0-9a-z_]+$/.test(tag)) {
            showMessage({
                text: `Tags can only contain lowercase characters, numbers and underscore`,
                type: "error",
            });
            throw "Invalid tag found";
        }
    }
    return true;
}
export function updateTagsCollection(
    writeBatch: WriteBatch,
    settingsDocumentKey: string,
    oldTags: string[],
    newTags: string[]
) {
    newTags.sort();
    validateTags(newTags);
    const addedTags = _.difference(newTags, oldTags);
    const removedTags = _.difference(oldTags, newTags);
    const updateTagsDoc = {};
    for (const tag of addedTags) {
        updateTagsDoc[`value.${tag}.count`] = increment(1);
    }
    for (const tag of removedTags) {
        updateTagsDoc[`value.${tag}.count`] = increment(-1);
    }
    writeBatch.update(
        firestoreRepository.documentReference(
            settingsRepository.COLLECTION_NAME,
            settingsDocumentKey
        ),
        updateTagsDoc
    );
}
export interface Unsubscribe {
    /** Removes the listener when invoked. */
    (): void;
}