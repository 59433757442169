
import DynamicModule from "@/components/form/DynamicModule.vue";
import {defineComponent} from "vue";
import { v4 as uuidv4 } from 'uuid';

type FormDialogParams = {
    persistent: boolean;
    maxWidth: string;
    hideOverlay: boolean;
    options: unknown;
};
export default defineComponent({
    name: "FormDialog",

    components: {DynamicModule},

    props: {
        paramsDialog: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    data(): {
        key: null | string;
        dialog: boolean;
        showLoading: boolean;
        nStep: number;
        steps: { title: string; component: any }[] | null;
        data: unknown;
        params: FormDialogParams;
    } {
        return {
            key: null,
            dialog: false,
            showLoading: false,
            nStep: 0,
            steps: null,
            data: {},
            params: {
                persistent: true,
                maxWidth: "1200px",
                hideOverlay: false,
                options: {},
            },
        };
    },

    computed: {
        step(): { title: string; component: any } | undefined {
            return this.steps?.[this.nStep];
        },
    },

    mounted(): void {

        this.params = {...this.params, ...this.paramsDialog};

        this.$eventBus.listen("showFormDialog", ({params, show}) => {
            if (show) {
                this.key = uuidv4();
                this.nStep = 0;
                this.steps = params.steps;
                if (Object.prototype.hasOwnProperty.call(params, "options")) {
                    this.params = {...this.params, ...params.options};
                }
                if (params.data !== undefined) {
                    this.data = params.data;
                }
            }
            this.dialog = show;
        });
    },

    methods: {
        nextStep(params) {
            this.nStep++;
            this.params = params;
        },

        senderDataEvent(data) {
            this.$emit("onDataEvent", data);
        },

        closeForm() {
            this.dialog = false;
        },
    },
});
