
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from 'vuex';

export default defineComponent({
    computed: {
        ...mapGetters('snackbar', {
            message: 'getMessage'
        }),

    },

    methods: {
        ...mapMutations('snackbar', ['HIDE_MESSAGE']),
        hideSnackbar() {
            this.HIDE_MESSAGE();
        }
    }

});
