import {defineComponent} from "vue";
import {Unsubscribe} from "@/repositories/common";

export default defineComponent({
    created() {
        this.unsubscribes = [];
    },
    setup() {
      return {
          unsubscribes: new Array<Unsubscribe>()
      };
    },
    methods: {
        unsubscribeAll() {
            this.unsubscribes.forEach(unsubscribe => {
                unsubscribe()
            })
            this.unsubscribes = []
        },
    },
    beforeDestroy() {
        this.unsubscribeAll()
    }
});
