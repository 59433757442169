enum TrainingGoal {
    Lifting = "lifting",
    Sculpting = "sculpting",
    Depuffing = "depuffing",
    Brightening = "brightening",
    Tightening = "tightening",
    Detoxing = "detoxing",
}

export default TrainingGoal;
