
import {defineComponent} from "vue";

export type ConfirmDialogOptions = {
    color: string,
    width: number,
    zIndex: number,
    noConfirm: boolean
}
export default defineComponent({
    name: "ConfirmDlg",
    data(): {
        dialog: boolean,
        resolve: ((boolean) => void) | null,
        message: string | null,
        title: string | null,
        options: ConfirmDialogOptions
    } {
        return {
            dialog: false,
            resolve: null,
            message: null,
            title: null,
            options: {
                color: "primary",
                width: 400,
                zIndex: 200,
                noConfirm: false,
            },
        };
    },

    methods: {
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, _) => {
                this.resolve = resolve;
            });
        },
        agree() {
            this.resolve?.(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve?.(false);
            this.dialog = false;
        },
    },
});
