export default {
    MODE: "test",
    NAME_SPACE: "backoffice",
    //RECAPTCHA_SITE_KEY: "6LccyT8cAAAAADWd6c0pmRRhn7cpMN5u-AYp_KzG",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyDLePJ9zfb-Z38FixGnRBd0EWMDbQ3xdP4",
        authDomain: "facegym-test.firebaseapp.com",
        projectId: "facegym-test",
        storageBucket: "facegym-test.appspot.com",
        messagingSenderId: "66108236511",
        appId: "1:66108236511:web:ca42d4678c445bffb0ff6a",
        measurementId: "G-39D0CX60V7"
    },
};
