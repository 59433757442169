
import elementsMixin from "./elementsMixin";
import Vue, {defineComponent, VueConstructor} from "vue";

export default defineComponent({
    name: "CSelect",
    mixins: [elementsMixin],
    data: () => ({
        items: [] as { key: string; value: string }[],
    }),
    async created() {
        await this.initItems();
    },
    methods: {
        async initItems() {
            const rawItems = this.field.items;
            if (Array.isArray(rawItems)) {
                this.setItems(rawItems);
                this.setInitialValue();
            } else if (typeof rawItems == "function") {
                // if it has 0 args we consider it a promise, else an observable
                if (rawItems.length == 0) {
                    const items = await rawItems();
                    this.setItems(items);
                    this.setInitialValue();
                } else {
                    rawItems((onNext) => {
                        this.setItems(onNext);
                        this.setInitialValue();
                    });
                }
            }
        },

        setItems(newItems) {
            this.items = newItems;
        },
        setInitialValue() {
            if (
                this.model[this.element] !== undefined &&
                this.model[this.element]?.value !== undefined
            ) {
                Vue.set(
                    this.model,
                    this.element,
                    this.items.find(
                        (item) => item.value === this.model[this.element]
                    )?.value
                );
                this.$emit('input', this.model)
            }
        },
        onInput(newValue) {
            Vue.set(this.model, this.element, newValue);
            this.$emit('input', this.model)
        }
    },
});
