
import {mapGetters} from 'vuex';
import Navbar from '@/components/Navbar.vue';
import SnackBar from "@/components/utils/SnackBar.vue";
import { Sortable, OnSpill, AutoScroll } from 'sortablejs/modular/sortable.core.esm';
Sortable.mount(new AutoScroll());
Sortable.mount(OnSpill);
export default {
    name: 'app',
    components: {SnackBar, Navbar},

    computed: {
        ...mapGetters('user', {
            userProfile: 'userProfile',
            loggedIn: 'loggedIn'
        })
    },
};
