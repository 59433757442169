
import {defineComponent} from "vue";
import filters from "./filters";
import Language from "@/model/Language";
import {FindOptionsWhereFilter} from "@/repositories/firestoreRepository";
import _ from "lodash-es";
import DbWorkout from "@/model/DbWorkout";
import DbProgram from "@/model/DbProgram";
import programRepository from "@/repositories/programRepository";
import {PropType} from "vue/types/options";
import {persistenceErrorHandler} from "@/components/utils/UIErrorHandler";
import {useFormBuilder} from "@/components/form/formBuilderMixin";

export default defineComponent({
    name: "ListPrograms",
    props: {
        showSelect: {
            type: Boolean,
            default: false,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        showFilters: {
            type: Boolean,
            default: true,
        },
        customFilters: {
            type: Array as PropType<FindOptionsWhereFilter[]>,
            default: () => [],
        },
    },
    data() {
        return {
            tableLoading: false,
            localFilters: new Array<FindOptionsWhereFilter>(),
            headers: [
                {
                    text: "Title",
                    value: `title.${Language.Default}`,
                    sortable: false,
                },
                {text: "Focus Areas", value: "focusAreas", sortable: false},
                {text: "Training Goals", value: "trainingGoals", sortable: false},
                {text: "Tags", value: "tags", sortable: false},
                {text: "Private", value: "isPrivate", sortable: false}
            ],
        };
    },
    setup(props, context) {
        const formBuilder = useFormBuilder(props, context, filters);
        return {
            ...formBuilder,
        };
    },
    computed: {
        filterQuery(): FindOptionsWhereFilter[] {
            return _.concat(this.localFilters, this.customFilters);
        }
    },

    methods: {
        filterItems(data) {
            const filterQuery: FindOptionsWhereFilter[] = [];

            const title = data?.filters?.title;
            if (title != undefined && title.length > 0) {
                filterQuery.push({
                    fieldPath: "title." + Language.Default,
                    opStr: "text-contains",
                    value: title,
                });
            }

            const tags = data?.filters?.tags;
            if (tags != undefined && tags.length > 0) {
                filterQuery.push({
                    fieldPath: "tags",
                    opStr: "array-contains-all",
                    value: tags,
                });
            }
            const focusAreas = data?.filters?.focusAreas;
            if (focusAreas != undefined && focusAreas.length > 0) {
                filterQuery.push({
                    fieldPath: "focusAreas",
                    opStr: "array-contains-all",
                    value: focusAreas,
                });
            }
            const trainingGoals = data?.filters?.trainingGoals;
            if (trainingGoals != undefined && trainingGoals.length > 0) {
                filterQuery.push({
                    fieldPath: "trainingGoals",
                    opStr: "array-contains-all",
                    value: trainingGoals,
                });
            }
            this.localFilters = filterQuery;
        },
        async onTogglePrivate(item: DbProgram) {
            const newPrivateValue = !item.isPrivate

            if (
                // Show alert only if it will be set as public
                newPrivateValue ||
                await (this.$refs.confirm as any).open(
                    this.$t(`confirm.publish.program.title`),
                    this.$t(`confirm.publish.program.message`)
                )
            ) {
                this.tableLoading = true;
                await programRepository.updateProgram({
                    id: item.id,
                    isPrivate: newPrivateValue
                }).catch(persistenceErrorHandler)
                this.tableLoading = false;
            }
        },
        openProgramView(item: DbWorkout) {
            this.$router.push(`/programs/${item.id}`);
        },

        clickAction({item, event}) {
            switch (event) {
                case "onRowClick":
                    if (!this.showSelect) {
                        this.openProgramView(item);
                    }
                    break;
                case "onRowSelection":
                    this.$emit("onDataEvent", {item, event});
                    break;
            }
        },
    },
});
