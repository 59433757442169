import firebaseApp from "@/firebaseApp";
import {getAuth} from "firebase/auth";
import store from "./store";

const checkAuthStatus: () => Promise<boolean> = () => {
    return new Promise((resolve, reject) => {
        try {
            const firebaseAuth = getAuth(firebaseApp);
            if (firebaseAuth == undefined) {
                reject("Firebase auth not available");
                return;
            }
            firebaseAuth.onAuthStateChanged(async (user) => {
                if (user) {
                    store.commit(`user/USER_DETAILS`, user);
                    resolve(true);
                } else {
                    store.commit(`user/LOGOUT`);
                    resolve(false);
                }
            });
        } catch {
            reject("api failed");
        }
    });
};

export default {
    checkAuthStatus,
};
